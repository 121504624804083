import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTable } from '@angular/material/table';

import userConfig from '../../assets/userConfig.json';

const numWords = require('num-words')

import { PrintItem } from '../../ItemTypes/PrintItem';
import { RadioItem } from '../../ItemTypes/RadioItem';
import { PrintItemTableComponent } from '../ItemTables/Print/PrintItemTable.component';
import { RadioItemTableComponent } from '../ItemTables/Radio/RadioItemTable.component';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DBAccessor } from '../../db/DBAccessor';
import { PrintService } from '../../printPages/printService.service';
import { NewInvoiceService } from '../Invoice/NewInvoiceService.service';
import { PrintClassifiedItemTableComponent } from '../ItemTables/PrintClassified/PrintClassifiedItemTable.component';
import { PrintClassifiedItem } from '../../ItemTypes/PrintClassifiedItem';
import { PrintFreeformItem } from '../../ItemTypes/PrintFreeformItem';
import { PrintFreeformItemTableComponent } from '../ItemTables/PrintFreeform/PrintFreeformItemTable.component';
import { Title } from '@angular/platform-browser';
import { OthersItemTableComponent } from '../ItemTables/Others/OthersItemTable.component';
import { OthersItem } from '../../ItemTypes/OthersItem';

const DateUtil = require('../../util/DateUtil.js');
@Component({
  selector: 'roform',
  templateUrl: './ROForm.component.html',
  styleUrls: ['./ROForm.component.css']
})



export class ROFormComponent implements OnInit, OnDestroy, AfterViewInit {

  subscriptionContainer = new Subscription();
  createMode = true
  editMode = true
  
  isSaving = false
  isUpdating = false
  isRefreshing = false
  isPrinting = false
  fromRO = false

  addresseeSearchValue = '';
  clientSearchValue = '';
  
  addresseeSearchInput: ElementRef;
  clientSearchInput: ElementRef;

  freqAddresseeList = [];
  freqClientList = [];
  freqPublicationsList = [];
  freqAddresseeListFiltered = [];
  freqClientListFiltered = [];
  dataToLoad
  RO_ID = null
  RO_rev = null
  public currDate = new Date();
  splitGst = true // separate flag for RO

  printItemsTableContainer: PrintItemTableComponent;
  printClassifiedItemsTableContainer: PrintClassifiedItemTableComponent;
  printFreeformItemsTableContainer: PrintFreeformItemTableComponent;
  radioItemsTableContainer: RadioItemTableComponent;
  othersItemsTableContainer: OthersItemTableComponent;
  
  //ROColumns = ['insertionDate','publication','insertionCount','caption','rate','width','height','size','amount']
  //ROItems = []
  headerObj = {
    addressee: "",
    billNo : "",
    billDate : new Date(),
    releaseType : "",
    GSTIN : "",
    PAN: "",
    HSNCode: "",
    hasNewLetterhead : false,
    client : {
      name: "",
      GSTIN: "",
      shortCode: ""
    },
    isInvoiceGenerated: false,
    splitGst : true,
    hasRemarks : false,
    remarks : "",
    showRefRO: true,
    refRO: {
      refRONum: "",
      refRODate: ""
    }
  }
  IDObj = {
    objType : "",
    billNo : "",
    publication : "",
    client : ""
  }
  totalObj = {
    grossAmount : 0,
    additionalComponents : [],
    subtotal : {
      description : "Subtotal",
      value : 0
    },
    CGST : {
      description : "CGST",
      value : 0
    },
    SGST : {
      description : "SGST",
      value : 0
    },
    totalGST : {
      description : "IGST",
      value : 0
    },
    netAmount : 0,
    amountInWords : "-"
  }

  
  @ViewChild('addresseeSearchInput', {static: false}) set addresseeSearchInputSet(content: ElementRef) {
    this.addresseeSearchInput = content;     
  }
  
  @ViewChild('clientSearchInput', {static: false}) set clientSearchInputSet(content: ElementRef) {
    this.clientSearchInput = content;     
  }
  
  @ViewChild('printItemsTableContainer', {static: false}) set printTableContent(content: PrintItemTableComponent) {
    this.printItemsTableContainer = content;
    if(content){
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "print"){
        this.setPrintItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('printClassifiedItemsTableContainer', {static: false}) set printClassifiedTableContent(content: PrintClassifiedItemTableComponent) {
    this.printClassifiedItemsTableContainer = content;
    if(content){
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printClassified"){
        this.setPrintClassifiedItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('printFreeformItemsTableContainer', {static: false}) set printFreeformTableContent(content: PrintFreeformItemTableComponent) {
    this.printFreeformItemsTableContainer = content;
    if(content){
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printFreeform"){
        this.setPrintFreeformItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('radioItemsTableContainer', {static: false}) set radioTablecontent(content: RadioItemTableComponent) {
    this.radioItemsTableContainer = content;
    if(content){
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "radio"){
        this.setRadioItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('othersItemsTableContainer', {static: false}) set othersTablecontent(content: OthersItemTableComponent) {
    this.othersItemsTableContainer = content;
    if(content){
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "others"){
        this.setOthersItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }
  
  

  constructor(private route: ActivatedRoute, private _snackBar: MatSnackBar, private dbAccessor: DBAccessor,
           private cdRef:ChangeDetectorRef, private router: Router, private printService: PrintService,
          private newInvoiceService: NewInvoiceService, private titleService: Title){
    //this.ROItems = []
    // this.ROItems.push(new PrintItem())
    //this.releaseType = "print";
    //this.headerObj.releaseType = "print";
    this.headerObj.GSTIN = userConfig.GSTIN;
    //this.headerObj.PAN = userConfig.PAN;
    this.headerObj.PAN = "AHFP" + "D1433Q";
    this.IDObj.objType = "RO";
    this.titleService.setTitle("Release Order");
    this.dbAccessor.getConfig().then((configFile) => {
      if(configFile){
        this.freqAddresseeList = configFile.freqAddressees;
        this.freqAddresseeListFiltered = this.freqAddresseeList;
        this.freqClientList = configFile.freqClients;
        this.freqClientListFiltered = this.freqClientList;
        this.freqPublicationsList = configFile.freqPublications;
      }
    });
  }
    
  ngOnInit(){
    this.subscriptionContainer.add(this.route.data.subscribe(async (data) => {
      this.createMode = data.createMode;
      this.editMode = data.editMode;
      this.fromRO = data.fromRO;

      let flowPromise = Promise.resolve();
      
      // Set bill nums for non-edit scenarios
      if(this.fromRO || this.createMode){
        this.IDObj.billNo = await this.dbAccessor.getNextDocNum('RO');
      }
      if(this.fromRO){
        let sourceData = this.newInvoiceService.getSourceData();
        if (sourceData) {
          this.headerObj = sourceData.header;
          this.dataToLoad = {
            header: sourceData.header,
            columnHeaderDetails: sourceData.columnHeaderDetails,
            items: sourceData.items
          }; // Items will be loaded by setter
          this.setROTotalData(sourceData);
          // Cleanup
          this.newInvoiceService.clearData();
        }
        
      }
      else if(this.createMode){
        this.headerObj.GSTIN = userConfig.GSTIN;
        this.headerObj.releaseType = "print"; // default
        this.headerObj.hasNewLetterhead = true;
        // Adding agency discount by default
        this.addPriceComponentClick('agencyDiscount', "Agency Discount @ 15%");
        //this.newPriceComponentChanged(this.totalObj.additionalComponents[0],null);
      }
      else{ // Opening existing RO
        this.subscriptionContainer.add(this.route.paramMap.subscribe((params) => {
          this.RO_ID = params.get('id');
            
          flowPromise = flowPromise.then(() => {
            return this.dbAccessor.getByID(this.RO_ID).then((ROData) => { // Checking for race condition
              this.dataToLoad = ROData;
              this.RO_rev = ROData._rev;
              this.IDObj.billNo = this.getBillNumFromID(ROData.header.billNo);
              this.setROHeaderData(ROData);
              this.setROTotalData(ROData);
            }).catch((err) => {
              console.error("Document not found");
              console.log("Additional error details: " + err);
            })
          });
        }));
      }
      flowPromise.then(() => {
        this.refreshBillNo(this.headerObj.billDate);
      });
    }));
  }

  ngAfterViewInit(){
    
  }

  ngOnDestroy(){
    this.subscriptionContainer.unsubscribe();
  }

  refreshRO(){
    // REVISIT THIS LATER
    //this.router.navigate(['/RO',this.RO_ID]);
    this.isRefreshing = true;
    window.location.reload();
  }

  setROHeaderData(inData){
    if(!inData)
      return;
    //this.headerObj = inData.header;
    
    let inHeader = inData.header;
    this.headerObj.addressee = inHeader.addressee;
    this.headerObj.billNo = inHeader.billNo;
    this.headerObj.billDate = inHeader.billDate;
    this.headerObj.releaseType = inHeader.releaseType;
    this.headerObj.isInvoiceGenerated = inHeader.isInvoiceGenerated || false;
    this.headerObj.PAN = inHeader.PAN;
    this.headerObj.GSTIN = inHeader.GSTIN;
    this.headerObj.HSNCode = inHeader.HSNCode;
    this.headerObj.hasNewLetterhead = inHeader.hasNewLetterhead || false;
    this.headerObj.client.name = inHeader.client.name;
    this.headerObj.client.GSTIN = inHeader.client.GSTIN;
    this.headerObj.hasRemarks = !!inHeader.hasRemarks;
    this.headerObj.remarks = inHeader.remarks;
    this.headerObj.splitGst = !!inHeader.splitGst;
    this.headerObj.showRefRO = !!inHeader.showRefRO;
    this.headerObj.refRO.refRONum = inHeader.refRO.refRONum;
    this.headerObj.refRO.refRODate = inHeader.refRO.refRODate;
    
  }

  setROTotalData(inData){
    if(!inData)
      return;
    this.totalObj = inData.totalObj;
    this.totalObj.additionalComponents.forEach((addComp) => {
      if(addComp.id > this.lastPriceComponentID)
        this.lastPriceComponentID = addComp.id;
    });
  }

  setPrintItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.printItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.printItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new PrintItem(inItem);
      this.printItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setPrintClassifiedItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.printClassifiedItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.printClassifiedItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new PrintClassifiedItem(inItem);
      this.printClassifiedItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setPrintFreeformItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.printFreeformItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.printFreeformItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new PrintFreeformItem(inItem);
      this.printFreeformItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setRadioItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.radioItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.radioItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new RadioItem(inItem);
      this.radioItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setOthersItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.othersItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.othersItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new OthersItem(inItem);
      this.othersItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }
  
  getBillNumFromID(billID){
    let billNum = "";
    if(billID.startsWith('ES/')){ // new pattern
      billNum = billID.split('/')[3];
    }
    else if(billID.startsWith('ES')){ // old pattern: ES5 etc
      billNum = billID.split('/')[0].split('ES')[1];
    }
    return billNum;
  }

  refreshBillNo(billDate){
    let outPrefix = "ES/" + DateUtil.getFinancialYear(billDate) + "/" + this.IDObj.objType + '/' + this.IDObj.billNo;
    let outSuffix = "";
    //let outID = "";
    /*if(this.IDObj.publication && this.IDObj.publication.length > 0){
      outSuffix += this.IDObj.publication;
    }
    if(this.IDObj.client && this.IDObj.client.length > 0){
      if(outSuffix.length > 0){
        outSuffix += '-';
      }
      outSuffix += this.IDObj.client;
    }*/
    if(this.headerObj.billNo == "" || this.headerObj.billNo.split('/')[0] == 'ES') // new format
      this.headerObj.billNo = outPrefix + outSuffix;
  }
  
  onAddresseeListItemClick(addressee){
    this.headerObj.addressee = addressee.name;
  }

  onClientListItemClick(client){
    this.headerObj.client.name = client.name;
    this.headerObj.client.GSTIN = client.GSTIN;
    this.headerObj.client.shortCode = client.shortCode;
  }
  
  addItemClick(){
    if(this.headerObj.releaseType == "print"){
      this.printItemsTableContainer.addItem(new PrintItem(null));
    }
    else if(this.headerObj.releaseType == "printClassified"){
      this.printClassifiedItemsTableContainer.addItem(new PrintClassifiedItem(null));
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      this.printFreeformItemsTableContainer.addItem(new PrintFreeformItem(null));
    }
    else if(this.headerObj.releaseType == "radio"){
      this.radioItemsTableContainer.addItem(new RadioItem(null));
    }
    else if(this.headerObj.releaseType == "others"){
      this.othersItemsTableContainer.addItem(new OthersItem(null));
    }
    
  }

  goToEditMode(){
    this.editMode = true;
  }

  
  getColumnHeaderDetails(){
    let columnHeaderDetailsObj = {};
    if(this.headerObj.releaseType == "print"){
      columnHeaderDetailsObj = this.printItemsTableContainer.getColumnHeaderDetails()
    }
    else if(this.headerObj.releaseType == "printClassified"){
      columnHeaderDetailsObj = this.printClassifiedItemsTableContainer.getColumnHeaderDetails()
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      columnHeaderDetailsObj = this.printFreeformItemsTableContainer.getColumnHeaderDetails()
    }
    else if(this.headerObj.releaseType == "radio"){
      columnHeaderDetailsObj = this.radioItemsTableContainer.getColumnHeaderDetails();
    }
    else if(this.headerObj.releaseType == "others"){
      columnHeaderDetailsObj = this.othersItemsTableContainer.getColumnHeaderDetails();
    }
    return columnHeaderDetailsObj;
  }
  
  getItemsArray(){
    let itemsArr = [];
    if(this.headerObj.releaseType == "print"){
      itemsArr = this.printItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "printClassified"){
      itemsArr = this.printClassifiedItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      itemsArr = this.printFreeformItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "radio"){
      itemsArr = this.radioItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "others"){
      itemsArr = this.othersItemsTableContainer.getItemsArray()
    }
    return itemsArr;
  }

  filterAddressees($event){
    if(!$event.srcElement.value || $event.srcElement.value.length == 0)
      this.freqAddresseeListFiltered = this.freqAddresseeList;
    else
      this.freqAddresseeListFiltered = this.freqAddresseeList.filter((el) => el.name.toLowerCase().includes($event.srcElement.value.toLowerCase()));
  }

  filterClients($event){
    if(!$event.srcElement.value || $event.srcElement.value.length == 0)
      this.freqClientListFiltered = this.freqClientList;
    else
      this.freqClientListFiltered = this.freqClientList.filter((el) => el.name.toLowerCase().includes($event.srcElement.value.toLowerCase()));
  }

  addresseeMenuOpened(){
    setTimeout(() => {
      this.addresseeSearchInput.nativeElement.focus();
    });
  }

  clientMenuOpened(){
    setTimeout(() => {
      this.clientSearchInput.nativeElement.focus();
    });
  }

  validateRO() {
    let checkStatusObj = {
      checkStatus : true,
      errorMessages : []
    }
    if(!this.headerObj.billNo){
      checkStatusObj.checkStatus = false;
      checkStatusObj.errorMessages.push("RO No. invalid");
    }
    if(!this.headerObj.client){
      checkStatusObj.checkStatus = false;
      checkStatusObj.errorMessages.push("Client name is mandatory");
    }
    // Generic items check
    let itemsArray = this.getItemsArray();
    // Ensure there are items
    if(itemsArray.length == 0){
      checkStatusObj.checkStatus = false;
      checkStatusObj.errorMessages.push("No items in RO");
    }
      
    // Check whether some item has zero amount
    itemsArray.some((item) => {
      if(isNaN(item.amount)){
        checkStatusObj.checkStatus = false;
        checkStatusObj.errorMessages.push("At least one item in the Invoice has an invalid amount");
        return true; // break loop
      }
      else if(item.amount <= 0){
        checkStatusObj.checkStatus = false;
        checkStatusObj.errorMessages.push("At least one item in RO has zero or less or invalid amount");
        return true; // break loop
      }
    });

    /* Specific checks
    if(this.headerObj.releaseType == "print"){
      
    }
    else if(this.headerObj.releaseType == "radio"){
      // Radio item validations
    }*/
    return checkStatusObj;
  }

  saveRO(){
    let checkStatusObj = this.validateRO();
    if(!checkStatusObj.checkStatus){
      // raise error
      checkStatusObj.errorMessages.forEach((errorMsg) => {
        this._snackBar.open(errorMsg, "", {
          duration: 3000,
        });
      });
      
      return;
    }
    if((this.headerObj.hasRemarks && this.headerObj.remarks.length == 0) || !this.headerObj.hasRemarks){
        this.headerObj.hasRemarks = false;
        this.headerObj.remarks = "";
    }

    let outObj = {
      header : this.headerObj,
      type : 'RO',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    };

    /*
    if(this.headerObj.releaseType == "print"){
      outObj.items = this.printItemsTableContainer.getItemsArray()
    }
    else
    else if(this.headerObj.releaseType == "radio"){
      outObj.items = this.radioItemsTableContainer.getItemsArray()
    }*/

    return this.dbAccessor.store(outObj).then((res:any) => {
      if(res.success) {
        this._snackBar.open("Successfully inserted RO : " + res.createdID, "", {
          duration: 3000,
        });
        this.isSaving = true;
        this.dbAccessor.incrementNum("RO").then((res) => {
          setTimeout(() => {
            this.router.navigate(['/RO']);
          }, 2000);
        });
      }
      else {
        let errorMsg = res.error || "Unknown error";
        this._snackBar.open("An error occurred while inserting RO : " + errorMsg, "", {
          duration: 3000,
        });
      }
    })
  }
  
  updateRO(){
    let checkStatusObj = this.validateRO();
    if(!checkStatusObj.checkStatus){
      // raise error
      checkStatusObj.errorMessages.forEach((errorMsg) => {
        this._snackBar.open(errorMsg, "", {
          duration: 3000,
        });
      });
      
      return;
    }
    if((this.headerObj.hasRemarks && this.headerObj.remarks.length == 0) || !this.headerObj.hasRemarks){
      this.headerObj.hasRemarks = false;
      this.headerObj.remarks = "";
    }
    let outObj = {
      _id : this.RO_ID,
      _rev : this.RO_rev,
      header : this.headerObj,
      type : 'RO',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    };


    return this.dbAccessor.update(outObj).then((res:any) => {
      if(res.success) {
        this._snackBar.open("Successfully updated RO : " + res.updatedID, "", {
          duration: 3000,
        });
        this.isUpdating = true;
      setTimeout(() => {
        this.router.navigate(['/RO']);
      }, 2000);
      }
      else {
        let errorMsg = res.error || "Unknown error";
        this._snackBar.open("An error occurred while updating RO : " + errorMsg, "", {
          duration: 3000,
        });
      }
    })
  }

  lastPriceComponentID = -1;

  getNewPriceComponentID(){
    return ++this.lastPriceComponentID;
  }

  addPriceComponentClick(inType, description = null){
    let newPriceComponent = {
      id : this.getNewPriceComponentID(),
      description : "",
      type : "discount",
      valueSign : "-",
      percentValue : 0, // only used for percentage price components
      value : 0
    };
    if(inType){
      newPriceComponent.type = inType;
    }
    if(description){
      newPriceComponent.description = description;
    }
    this.totalObj.additionalComponents.push(newPriceComponent);
  }

  deletePriceComponentClick(delPriceComponent){
    let foundIndex = this.totalObj.additionalComponents.findIndex((prcComponent) => prcComponent.id == delPriceComponent.id);
    if(foundIndex > -1){
      this.totalObj.additionalComponents.splice(foundIndex, 1);
      this.calculateNetAmount();
    }
  }

  

  calculateSubtotal(){
    let subtotalVal;
    //this.calculateGrossAmount();
    subtotalVal = this.totalObj.grossAmount;
    this.totalObj.additionalComponents.forEach((prcComponent) => {
      if(prcComponent.type == "percentDiscount" || prcComponent.type == "agencyDiscount") {
        if(prcComponent.percentValue == null || prcComponent.percentValue == ""){
          if(prcComponent.type == "percentDiscount")
            prcComponent.percentValue = 0;
          else
            prcComponent.percentValue = 15;
        }
        prcComponent.value = subtotalVal * (prcComponent.percentValue / 100);
      }
      if(prcComponent.valueSign == '+'){
        subtotalVal += parseInt(prcComponent.value) || 0;
      }
      else if(prcComponent.valueSign == '-'){
        subtotalVal -= parseInt(prcComponent.value) || 0;
      }
    });

    this.totalObj.subtotal.value = subtotalVal;
    return this.totalObj.subtotal;
  }

  calculateGST(){
    let gstPercent, gstAmount;
    if(this.headerObj.releaseType == "print" || this.headerObj.releaseType == "printClassified" || this.headerObj.releaseType == "printFreeform")
      gstPercent = 5;
    else
      gstPercent = 18;
    
    let subtotalObj = this.totalObj.subtotal; // after discounts
    let subtotalValue = subtotalObj.value;
    gstAmount = (gstPercent / 100) * subtotalValue;

    this.totalObj.SGST = {
      description : "SGST @ " + gstPercent/2 + "%",
      value : gstAmount / 2
    };
    this.totalObj.CGST = {
      description : "CGST @ " + gstPercent/2 + "%",
      value : gstAmount / 2
    };
    this.totalObj.totalGST = {
      description : "GST @ " + gstPercent + "%",
      value : gstAmount
    };

    return this.totalObj.totalGST;

  }

  calculateGrossAmount(){
    let grossAmount = 0;
    let items = this.getItemsArray();
    items.forEach((item) => {
      if(!isNaN(item.amount))
        grossAmount += item.amount;
    });
    this.totalObj.grossAmount = grossAmount;
    //this.calculateNetAmount();
  }

  toggleSplitGst(){
    this.headerObj.splitGst = !this.headerObj.splitGst;
  }

  newPriceComponentChanged(priceComponent, event){
    if(priceComponent.type == "agencyDiscount"){
      priceComponent.description = priceComponent.description || "Agency Discount @ 15%";
      priceComponent.valueSign = '-';
      priceComponent.percentValue = 15;
      priceComponent.value = this.totalObj.grossAmount * 0.15;
    }
    else if(priceComponent.type == "percentDiscount"){
      priceComponent.valueSign = '-';
      priceComponent.description = priceComponent.description || "Percent Discount";
      priceComponent.percentValue = (priceComponent.percentValue > 0)? priceComponent.percentValue : 0;
      priceComponent.value = this.totalObj.grossAmount * (priceComponent.percentValue / 100);
    }
    else if(priceComponent.type == "discount"){
      priceComponent.description = priceComponent.description || "Discount";
      priceComponent.valueSign = '-';
    }
    else if(priceComponent.type == "surcharge"){
      priceComponent.description = priceComponent.description || "Premium";
      priceComponent.valueSign = '+';
    }
    this.calculateNetAmount();
  }


  calcAndSetAmountInWords(){
    let netAmount = Math.trunc(this.totalObj.netAmount);
    let netAmountWords = numWords(netAmount);
    let isFirstLetter = true;
    if(!netAmountWords)
      return;
    let netAmountWordsArr = [...netAmountWords];
    for(let i = 0; i < netAmountWordsArr.length; i++){
      if(isFirstLetter && netAmountWordsArr[i] != 'a'){
        netAmountWordsArr[i] = netAmountWordsArr[i].toUpperCase(); // making it capital letters
      }
      if(isFirstLetter)
        isFirstLetter = false;
      else{
        if(netAmountWordsArr[i] == ' ')
          isFirstLetter = true;
      }
    }
    this.totalObj.amountInWords = netAmountWordsArr.join('') + ' Rupees only';
  }

  calculateNetAmount(itemsArr = null){
    if(this.editMode){
      this.calculateGrossAmount();
      this.calculateSubtotal();
      let gstObj = this.calculateGST();
      this.totalObj.netAmount = this.totalObj.subtotal.value + gstObj.value;
      // Round-off
      this.totalObj.netAmount -= (this.totalObj.netAmount % 1);
      this.calcAndSetAmountInWords();
    }
  }

  createROCopy(){
    let outROData = {
      RO_ID : this.RO_ID,
      RO_rev : this.RO_rev,
      header : this.headerObj,
      type : 'RO',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    }
    this.newInvoiceService.createNewRO(outROData);
  }

  createProforma(){
    let outROData = {
      RO_ID : this.RO_ID,
      RO_rev : this.RO_rev,
      header : this.headerObj,
      type : 'Proforma',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    }
    this.newInvoiceService.createNewInvoice(outROData);
  }

  createInvoice(){
    let outROData = {
      RO_ID : this.RO_ID,
      RO_rev : this.RO_rev,
      header : this.headerObj,
      type : 'Invoice',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    }
    this.newInvoiceService.createNewInvoice(outROData);
  }

  printRO(){
    //this.router.navigate
    this.isPrinting = true;
    this.printService.printDocument("RO",this.RO_ID);
  }

  /*reloadPage(){
    this.router.navigate(['/RO']);
  }*/
}


