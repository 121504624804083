export class PrintFreeformItem {
  
    insertionDate : Date
    publication : string
    publicationShortCode : string
    insertionCount : number
    caption : string
    rate : number
    amount : number
  
    constructor(inItem){
      if(inItem){
        this.insertionDate = inItem.insertionDate;
        this.publication = inItem.publication;
        this.publicationShortCode = inItem.publicationShortCode;
        this.insertionCount = inItem.insertionCount;
        this.caption = inItem.caption;
        this.rate = inItem.rate;
        this.amount = inItem.amount;
      }
      else{
        this.insertionDate = new Date();
        this.insertionDate.setDate(new Date().getDate() + 1);
        this.publication = "";
        this.publicationShortCode = "";
        this.insertionCount = null;
        this.caption = "";
        this.rate = null;
        this.amount = null;
      }
      
    }
  }