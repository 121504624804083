// This class is mainly for converting JSON stringify-parsed objects back to their original forms (eg Date strings to Dates)
// CURRENTLY NOT USED
export class ImportHelper {
    constructor(){
        return this;
    }
    processFileToImport(inImportObjArr){
        this.processROs(inImportObjArr);
        this.processInvoices(inImportObjArr);
        return inImportObjArr;
    }

    private processROs(inImportObjArr){
        if(inImportObjArr){
            inImportObjArr.forEach((doc) => {
                doc.items.forEach((docItem) => {
                    docItem.insertionDate = new Date(docItem.insertionDate);
                })
            })
        }
    }

    private processInvoices(inImportObjArr){

    }
}