import { Component, ViewChild, Input, AfterViewInit, OnInit, ChangeDetectorRef, EventEmitter, Output, ElementRef } from '@angular/core';
import { PrintFreeformItem } from '../../../ItemTypes/PrintFreeformItem';
import { MatTable } from '@angular/material/table';

@Component({
  selector: '[PrintFreeformItemTable]',
  templateUrl: './PrintFreeformItemTable.component.html',
  styleUrls: ['./PrintFreeformItemTable.component.css']
})

export class PrintFreeformItemTableComponent implements OnInit, AfterViewInit{
  printItems : PrintFreeformItem[] = []
  public currDate = new Date();
  public topPublicationShortCode = "";

  publicationSearchValue = '';
  freqPublicationsListFiltered = [];

  pubSearchInput: ElementRef;
  
  @Input() createMode : Boolean = true
  @Input() editMode : Boolean = true
  @Input() printMode : Boolean = false

  @Input() freqPublicationsList = []

  @Output() publicationTopModified = new EventEmitter();
  @Output() itemTotalChange = new EventEmitter();

  PrintItemColumnsWithoutActions = ['insertionDate','publication','insertionCount','caption','rate','amount']
  PrintItemColumns = []

  columnHeaderDetails = {
  }

  @ViewChild('pubSearchInput', {static: false}) set pubSearchInputSet(content: ElementRef) {
    this.pubSearchInput = content;     
  }

  @ViewChild('printFreeformItemsTable', {static: false}) printItemsTableElement: MatTable<any>;
  
  constructor(private cdRef:ChangeDetectorRef){
    return this;
  }
  
  ngOnInit(){
    this.PrintItemColumns = Array.from(this.PrintItemColumnsWithoutActions);
    this.freqPublicationsListFiltered = this.freqPublicationsList;
    if(!this.printMode){
      this.PrintItemColumns.push('deleteBtn');
    }
  }
  
  ngAfterViewInit(){
    if(this.createMode && this.printItems.length == 0){
      this.addItem(new PrintFreeformItem(null));
    }
      
    this.cdRef.detectChanges();
  }

  addItem(newPrintFreeformItem : PrintFreeformItem){
    this.printItems.push(newPrintFreeformItem);
    this.refreshItemTable();
    //console.log(this.printItems)
    
  }
  onDeleteButtonClick(event, row){
    let confirmResult = confirm("Are you sure you want to delete this item?");
    if(confirmResult)
      this.deleteItem(row);
  }

  deleteItem(delRow){
    let foundIndex = this.printItems.findIndex((printItemRow) => printItemRow == delRow);
    if(foundIndex > -1){
      this.printItems.splice(foundIndex,1);
      this.refreshItemTable();
      this.itemTotalChange.emit(this.printItems);
    }
    if(this.printItems.length == 0){
      this.topPublicationShortCode = "";
      this.publicationTopModified.emit(this.topPublicationShortCode);
    }
    
  }

  getColumnHeaderDetails(){
    return this.columnHeaderDetails;
  }

  setColumnHeaderDetails(inHeaderDetails){
    Object.keys(inHeaderDetails).forEach((columnID) => {
      this.columnHeaderDetails[columnID] = inHeaderDetails[columnID];
    })
  }

  calculateAmount(row){
    //row.calculateAmount();
    this.itemTotalChange.emit(this.printItems);
  }

  clearItems(){
    this.printItems = [];
    this.refreshItemTable();
    this.itemTotalChange.emit(this.printItems);
    this.publicationTopModified.emit("");
  }

  refreshItemTable(){
    this.printItemsTableElement.renderRows();
  }

  getItemsArray(){
    return this.printItems;
  }
  
  filterPublications($event){
    if(!$event.srcElement.value || $event.srcElement.value.length == 0)
      this.freqPublicationsListFiltered = this.freqPublicationsList;
    else
      this.freqPublicationsListFiltered = this.freqPublicationsList.filter((el) => el.name.toLowerCase().includes($event.srcElement.value.toLowerCase()) 
        || (el.shortCode && el.shortCode.toLowerCase().includes($event.srcElement.value.toLowerCase())));
  }

  publicationMenuOpened(){
    setTimeout(() => {
      this.pubSearchInput.nativeElement.focus();
    });
  }
  
  onPublicationsListItemClick(index, publication){
    this.printItems[index].publication = publication.name;
    this.printItems[index].publicationShortCode = publication.shortCode;
    if(!this.topPublicationShortCode || this.topPublicationShortCode.length == 0){
      this.topPublicationShortCode = publication.shortCode;
      this.publicationTopModified.emit(this.topPublicationShortCode);
    }
  }

}
