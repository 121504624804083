const DateUtil = require('../util/DateUtil.js');
export class RadioItem {
  
    campaignDate : Date
    campaignDateStr : string
    publication : string
    publicationShortCode : string
    insertionCount : number
    caption : string
    rate : number
    jingleLength : number
    billingUnitCount : number
    runsPerDay : number
    comment : string
    amount : number
  
    constructor(inItem){
      if(inItem){
        this.campaignDate = inItem.campaignDate;
        if(!inItem.campaignDateStr)
          this.campaignDateStr = DateUtil.getFormattedDate(this.campaignDate);
        else
          this.campaignDateStr = inItem.campaignDateStr;
        this.publication = inItem.publication;
        this.publicationShortCode = inItem.publicationShortCode;
        this.insertionCount = inItem.insertionCount;
        this.caption = inItem.caption;
        this.rate = inItem.rate;
        this.jingleLength = inItem.jingleLength;
        this.billingUnitCount = inItem.billingUnitCount;
        this.runsPerDay = inItem.runsPerDay;
        this.comment = inItem.comment;
        this.amount = inItem.amount;
      }
      else{
        //this.campaignDate = new Date();
        this.campaignDateStr = "";
        this.publication = "";
        this.publicationShortCode = "";
        this.insertionCount = null;
        this.caption = "";
        this.rate = null;
        this.jingleLength = null;
        this.billingUnitCount = 0;
        this.runsPerDay = 1;
        this.comment = "";
        this.amount = null;
      }
      
    }


    calculateBillingUnits(){
      this.billingUnitCount = this.jingleLength / 10;
    }

    calculateAmount(){
      if(this.insertionCount && this.rate && this.billingUnitCount && this.runsPerDay){
        this.amount = this.insertionCount * this.rate * this.billingUnitCount * this.runsPerDay;
      }
      else
        this.amount = 0;
    }
  }