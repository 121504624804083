import { Component } from '@angular/core';
import { DBAccessor } from '../../db/DBAccessor';

import { AppComponent } from '../../app/app.component';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'gst-listpage',
    templateUrl: './GSTListPage.component.html',
    styleUrls: ['./GSTListPage.component.css'],
  })
  
  export class GSTListPageComponent {

    invoicesArr = []
    draftInvoicesArr = []
    // invoiceID, client, GST
    totalGst = 0;

    constructor(private dbAccessor: DBAccessor, private appComponent: AppComponent, private titleService: Title){
      this.titleService.setTitle("GST");
      this.loadContents();
    }

    loadContents(){
      this.appComponent.setScreenBusyState(true);
      this.dbAccessor.retrieveAllByType("Invoice").then((res) => {
        if(res.success){
          res.docs.forEach((resDoc)=>{
            let invoiceObj = {
              id: resDoc._id,
              invoiceID: resDoc.header.billNo,
              invoiceClient: resDoc.header.addressee.name,
              GstAmount: resDoc.totalObj && resDoc.totalObj.totalGST.value
            };
            if(resDoc.header.isDraft){
              this.draftInvoicesArr.push(invoiceObj);
            }
            else{
              this.invoicesArr.push(invoiceObj);
              this.totalGst += invoiceObj.GstAmount;
            }
          });
        }
        
      this.appComponent.setScreenBusyState(false);
      });
    }
  }