import { Pipe, PipeTransform } from '@angular/core';
const DateUtil = require('./DateUtil.js');

@Pipe({
  name: 'dateLocalePipe',
  pure: true
})
export class DateLocaleTransformPipe implements PipeTransform {
  transform(date: Date): any {
      return new Date(date).toLocaleString("en-IN");
  }
}