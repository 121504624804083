import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NewInvoiceService {
    sourceData: any;
  constructor(private router: Router) { }

  createNewRO(data: any){
    this.sourceData = data;
    this.router.navigate(['/RO','copy']);
  }

  createNewInvoice(data: any, isCopy = false){
      this.sourceData = data;
      let outObjType = data.type;
      if(outObjType == "Proforma"){
        if(!isCopy)
          this.router.navigate(['/Proforma','build']);
        else
          this.router.navigate(['/Proforma','copy']);
      }
      else if(outObjType == "Invoice"){
        if(!isCopy)
          this.router.navigate(['/Invoice','build']);
          else
            this.router.navigate(['/Invoice','copy']);
      }
      // ROData will be cleared by Invoice component
  }

  getSourceData(){
      return this.sourceData;
  }
  
  clearData(){
      this.sourceData = null;
  }

  onDataReady() {/*
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
    });*/
  }
}