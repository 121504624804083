import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router) { }

  printDocument(documentType: string, documentID: string) {
    this.isPrinting = true;
    /*this.router.navigate(['/',
      { outlets: {
        'print-outlet': ['print', documentType, documentID]
      }}]);*/
    this.router.navigate(['print', documentType, documentID]);
  }

  onDataReady() {/*
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
    });*/
  }
}