import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit } from '@angular/core';
import { DBAccessor } from '../../db/DBAccessor';
import { PrintService } from '../printService.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import userConfig from '../../assets/userConfig.json';
import { PrintItemTableComponent } from '../../forms/ItemTables/Print/PrintItemTable.component';
import { RadioItemTableComponent } from '../../forms/ItemTables/Radio/RadioItemTable.component';
import { PrintItem } from '../../ItemTypes/PrintItem';
import { RadioItem } from '../../ItemTypes/RadioItem';
import { NewInvoiceService } from '../../forms/Invoice/NewInvoiceService.service';
import { PrintClassifiedItem } from '../../ItemTypes/PrintClassifiedItem';
import { PrintClassifiedItemTableComponent } from '../../forms/ItemTables/PrintClassified/PrintClassifiedItemTable.component';
import { PrintFreeformItem } from '../../ItemTypes/PrintFreeformItem';
import { PrintFreeformItemTableComponent } from '../../forms/ItemTables/PrintFreeform/PrintFreeformItemTable.component';
import { Title } from '@angular/platform-browser';
import { OthersItemTableComponent } from '../../forms/ItemTables/Others/OthersItemTable.component';
import { OthersItem } from '../../ItemTypes/OthersItem';

@Component({
  selector: 'ROPrintPage',
  templateUrl: './ROPrintPage.component.html',
  styleUrls: ['./ROPrintPage.component.css']
})
export class ROPrintPageComponent implements OnInit, AfterViewInit {

  subscriptionContainer = new Subscription();

  addresseeList = []
  //@ViewChild('printItemsTableContainer', {static: false}) printItemsTableContainer: PrintItemTableComponent;
  //@ViewChild('radioItemsTableContainer', {static: false}) radioItemsTableContainer: RadioItemTableComponent;
  
  printItemsTableContainer: PrintItemTableComponent;
  printClassifiedItemsTableContainer: PrintClassifiedItemTableComponent;
  printFreeformItemsTableContainer: PrintFreeformItemTableComponent;
  radioItemsTableContainer: RadioItemTableComponent;
  othersItemsTableContainer: OthersItemTableComponent;

  headerObj = {
    addressee: "",
    billNo : "",
    billDate : new Date(),
    releaseType : "",
    GSTIN : "",
    HSNCode : "",
    hasNewLetterhead : false,
    splitGst : true,
    client : "",
    hasRemarks : false,
    remarks : ""
  }
  totalObj = {
    grossAmount : 0,
    additionalComponents : [],
    subtotal : {
      description : "Subtotal",
      value : 0
    },
    CGST : {
      description : "CGST",
      value : 0
    },
    SGST : {
      description : "SGST",
      value : 0
    },
    totalGST : {
      description : "GST",
      value : 0
    },
    netAmount : 0,
    amountInWords : "-"
  }

  
  dataToLoad
  RO_ID = null
  RO_rev = null
  splitGst = true
  HSNCode = ""

  constructor(private route: ActivatedRoute, private _snackBar: MatSnackBar, private dbAccessor: DBAccessor,
    private cdRef: ChangeDetectorRef, private router: Router, private printService: PrintService,
    private newInvoiceService: NewInvoiceService, private titleService: Title) {
    //this.ROItems = []
    // this.ROItems.push(new PrintItem())
    //this.releaseType = "print";
    //this.headerObj.releaseType = "print";
    this.headerObj.GSTIN = userConfig.GSTIN;
  }

  ngOnInit() {
    /*
    this.subscriptionContainer.add(this.route.data.subscribe((data) => {
      this.createMode = data.createMode;
      this.editMode = data.editMode;
    }));*/
  }

  ngAfterViewInit() {
    this.subscriptionContainer.add(this.route.paramMap.subscribe((params) => {
      this.RO_ID = params.get('RO_ID');
      if (!this.RO_ID) {
        return this;
      }

      this.dbAccessor.getByID(this.RO_ID).then((ROData) => {
        this.dataToLoad = ROData;
        this.RO_rev = ROData._rev;
        this.setROHeaderData(ROData);
        this.setROTotalData(ROData);
        let titleText = "RO_" + ROData.header.billNo;
        if(ROData.header.client.shortCode)
          titleText += ' ' + ROData.header.client.shortCode;
        let billDateObj = new Date(ROData.header.billDate);
        titleText += ' ' + billDateObj.toLocaleDateString('default', {day: "numeric",month: "short"})
        let firstPublication = "";
        if(ROData.items && ROData.items.length > 0){
          firstPublication = ROData.items[0].publicationShortCode || "";
        }
        if(firstPublication && firstPublication.length > 0)
          titleText += ' ' + firstPublication;
        this.titleService.setTitle(titleText);
        //this.setItemData(ROData);
        //this.dataToLoad = null;
      }).catch((err) => {
        console.error("Document not found");
        console.log("Additional error details: " + err);
      })
    }));
  }

  ngOnDestroy() {
    this.subscriptionContainer.unsubscribe();
  }

  @ViewChild('printItemsTableContainer', {static: false}) set printTablecontent(content: PrintItemTableComponent) {
    this.printItemsTableContainer = content;
    if(content){
      this.HSNCode = "998363";
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "print"){
        this.setPrintItemsData(this.dataToLoad);
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('printClassifiedItemsTableContainer', {static: false}) set printClassifiedTablecontent(content: PrintClassifiedItemTableComponent) {
    this.printClassifiedItemsTableContainer = content;
    if(content){
      this.HSNCode = "998363";
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printClassified"){
        this.setPrintClassifiedItemsData(this.dataToLoad);
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('printFreeformItemsTableContainer', {static: false}) set printFreeformTablecontent(content: PrintFreeformItemTableComponent) {
    this.printFreeformItemsTableContainer = content;
    if(content){
      this.HSNCode = "998363";
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printFreeform"){
        this.setPrintFreeformItemsData(this.dataToLoad);
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('radioItemsTableContainer', {static: false}) set radioTablecontent(content: RadioItemTableComponent) {
    this.radioItemsTableContainer = content;
    if(content){
      this.HSNCode = "998364";
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "radio"){
        this.setRadioItemsData(this.dataToLoad);
        //this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  @ViewChild('othersItemsTableContainer', {static: false}) set othersTablecontent(content: OthersItemTableComponent) {
    this.othersItemsTableContainer = content;
    if(content){
      this.HSNCode = this.headerObj.HSNCode;
      //this.dataToLoad = content;
      if(this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "others"){
        this.setOthersItemsData(this.dataToLoad);
        //this.calculateNetAmount()
        this.dataToLoad = null;
      }
        
    }
  }

  setROHeaderData(inData){
    if(!inData)
      return;
    this.headerObj = inData.header;
  }

  setROTotalData(inData){
    if(!inData)
      return;
    this.totalObj = inData.totalObj;
  }

  getItemsArray(){
    let itemsArr = [];
    if(this.headerObj.releaseType == "print"){
      itemsArr = this.printItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "printClassified"){
      itemsArr = this.printClassifiedItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      itemsArr = this.printFreeformItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "radio"){
      itemsArr = this.radioItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "others"){
      itemsArr = this.othersItemsTableContainer.getItemsArray()
    }
    return itemsArr;
  }

  setItemData(inData){
    if(inData.header.releaseType == "print"){
      this.setPrintItemsData(inData);
    }
    else if(inData.header.releaseType == "printClassified"){
      this.setPrintClassifiedItemsData(inData);
    }
    else if(inData.header.releaseType == "printFreeform"){
      this.setPrintFreeformItemsData(inData);
    }
    else if(inData.header.releaseType == "radio"){
      this.setRadioItemsData(inData);
    }
    else if(inData.header.releaseType == "others"){
      this.setOthersItemsData(inData);
    }
  }

  setPrintItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.printItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.printItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new PrintItem(inItem);
      this.printItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setPrintClassifiedItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.printClassifiedItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.printClassifiedItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new PrintClassifiedItem(inItem);
      this.printClassifiedItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setPrintFreeformItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.printFreeformItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.printFreeformItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new PrintFreeformItem(inItem);
      this.printFreeformItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setRadioItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.radioItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.radioItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new RadioItem(inItem);
      this.radioItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  setOthersItemsData(inData){
    if(!inData)
      return;
    let ROData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items : inData.items
    };
    if(ROData.columnHeaderDetails){
      this.othersItemsTableContainer.setColumnHeaderDetails(ROData.columnHeaderDetails);
    }
    this.othersItemsTableContainer.clearItems();
    ROData.items.forEach((inItem) => {
      let ROItem = new OthersItem(inItem);
      this.othersItemsTableContainer.addItem(ROItem);
    });
    this.cdRef.detectChanges();
  }

  onAddresseeListItemClick(addressee){
    this.headerObj.addressee = addressee;
  }

  printRO(){
    window.print();
  }

  createProforma(){
    let outROData = {
      RO_ID : this.RO_ID,
      RO_rev : this.RO_rev,
      header : this.headerObj,
      type : 'Proforma',
      items : this.getItemsArray(),
      totalObj : this.totalObj
    }
    this.newInvoiceService.createNewInvoice(outROData);
  }

  createInvoice(){
    let outROData = {
      RO_ID : this.RO_ID,
      RO_rev : this.RO_rev,
      header : this.headerObj,
      type : 'Invoice',
      items : this.getItemsArray(),
      totalObj : this.totalObj
    }
    this.newInvoiceService.createNewInvoice(outROData);
  }
}
