export class PrintItem {
  
    insertionDate : Date
    publication : string
    publicationShortCode : string
    insertionCount : number
    caption : string
    rate : number
    width : number
    height : number
    area : number
    amount : number
  
    constructor(inItem){
      if(inItem){
        this.insertionDate = inItem.insertionDate;
        this.publication = inItem.publication;
        this.publicationShortCode = inItem.publicationShortCode;
        this.insertionCount = inItem.insertionCount;
        this.caption = inItem.caption;
        this.rate = inItem.rate;
        this.width = inItem.width;
        this.height = inItem.height;
        this.area = inItem.area;
        this.amount = inItem.amount;
      }
      else{
        this.insertionDate = new Date();
        this.insertionDate.setDate(new Date().getDate() + 1);
        this.publication = "";
        this.publicationShortCode = "";
        this.insertionCount = null;
        this.caption = "";
        this.rate = null;
        this.width = null;
        this.height = null;
        this.area = 0;
        this.amount = 0;
      }
      
    }


    calculateArea(){
      if(this.width && this.height){
        this.area = this.width * this.height;
      }
      else
        this.area = 0;
      this.calculateAmount();
    }

    calculateAmount(){
      if(this.insertionCount && this.rate && this.area){
        this.amount = this.insertionCount * this.rate * this.area;
      }
      else
        this.amount = 0;
    }
  }