function getFormattedDate(date){
    let tempDate = new Date(date);
    let dd = tempDate.getDate();
    let mm = tempDate.getMonth() + 1;
    let yy = tempDate.getFullYear();
    if(dd < 10){
        dd = "0" + dd;
    }
    if(mm < 10){
        mm = "0" + mm;
    }
    return "" + dd + "/" + mm + "/" + yy;
}

function getDateText(date){
    if(date && date != ""){
        let tempDate = new Date(date);
        const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }) 
        let [{ value: mo },,{ value: da },,{ value: ye }] = dtf.formatToParts(tempDate);
        let outStr = da + " " + mo + " " + ye;
        return outStr;
    }
    else return "";
}

function getFinancialYear(inDate){
    let date;
    if(!inDate)
        date = new Date();
    else
        date = new Date(inDate);
    let startYear, endYear;
    let outStr;
    if(date.getMonth() < 3){
        startYear = date.getFullYear() - 1;
        endYear = startYear + 1;
    }
    else{
        startYear = date.getFullYear();
        endYear = startYear + 1;
    }
    outStr = startYear.toString().substring(2) + "-" + endYear.toString().substring(2);
    return outStr;
}

function twoDigits(d) {
    if(0 <= d && d < 10) return "0" + d.toString();
    if(-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
}

function toMysqlFormat(dateTime) {
    return dateTime.getFullYear() + "-" + twoDigits(1 + dateTime.getMonth()) + "-" + twoDigits(dateTime.getDate()) + " " + twoDigits(dateTime.getHours()) + ":" + twoDigits(dateTime.getMinutes()) + ":" + twoDigits(dateTime.getSeconds());
};

module.exports = {getFormattedDate, getDateText, getFinancialYear, toMysqlFormat};