import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: '[ConfigElementListComponent]',
  templateUrl: './ConfigElementList.component.html',
  styleUrls: ['./ConfigElementList.component.css']
})

export class ConfigElementListComponent {
  isAdding = true
  isEditing = false
  editingIndex = null
  changesMade = false;
  
  @Input() elementType : string;
  @Input() elementArr = [];
  @Input() hasGSTIN : Boolean = false;
  @Input() hasShortCode : Boolean = false;
  @Input() attribArr = [];
  /* Attrib structure: attribName, attribText, attribValue */
  @Output() elementChangedEmitter = new EventEmitter();

  @ViewChild('newElementName', {static: false}) newElementName;
  @ViewChild('newElementGSTIN', {static: false}) newElementGSTIN;
  @ViewChild('newElementShortCode', {static: false}) newElementShortCode;
  @ViewChild('editElementName', {static: false}) editElementName;
  @ViewChild('editElementGSTIN', {static: false}) editElementGSTIN;
  @ViewChild('editElementShortCode', {static: false}) editElementShortCode;
  
  @ViewChild('editElementContent', {static: false}) set content(content) {
    //this.editElementContent = content;
    if(content){
      if(this.isEditing && this.editingIndex !== undefined){
        //content.nativeElement.value = this.elementArr[this.editingIndex];
        //this.setEditFieldValue(this.elementArr[this.editingIndex]);
        //this.editingIndex = null;
        //
        this.editElementName.nativeElement.value = this.elementArr[this.editingIndex].name;
        if(this.hasGSTIN){
          this.editElementGSTIN.nativeElement.value = this.elementArr[this.editingIndex].GSTIN;
        }
        if(this.hasShortCode){
          this.editElementShortCode.nativeElement.value = this.elementArr[this.editingIndex].shortCode || "";
        }
      }
    }
  }
/*
  setEditFieldValue(value){
    if(this.editElementContent){
      this.editElementContent.nativeElement.value = value;
    }
  }
 */
  constructor(private _snackBar : MatSnackBar){

  }

  goAdd(){
    let newEl = {
      name: this.newElementName.nativeElement.value,
      GSTIN: "",
      shortCode: "",
      attribs: []
    };
    if(this.hasGSTIN){
      newEl.GSTIN = this.newElementGSTIN.nativeElement.value;
    }
    if(this.hasShortCode){
      newEl.shortCode = this.newElementShortCode.nativeElement.value;
    }
    
    /*attribArr.array.forEach(attrib => {
      
    });*/
    this.elementArr.push(newEl);
    
    this.newElementName.nativeElement.value = "";
    if(this.hasGSTIN)
      this.newElementGSTIN.nativeElement.value = "";
    if(this.hasShortCode)
      this.newElementShortCode.nativeElement.value = "";
    this._snackBar.open("Successfully inserted new " + this.elementType, "", {
      duration: 1500,
    });
    this.changesMade = true;
    this.elementChangedEmitter.emit();
  }

  goEditStart(index){
    this.isAdding = false;
    this.isEditing = true;
    this.editingIndex = index;
    //this.setEditFieldValue(this.elementArr[this.editingIndex]);
  }

  goEditCancel(){
    //this.editElementContent
    this.isAdding = true;
    this.isEditing = false;
    this.editingIndex = null;

  }

  goEditEnd(){
    this.elementArr[this.editingIndex].name = this.editElementName.nativeElement.value;
    if(this.hasGSTIN)
      this.elementArr[this.editingIndex].GSTIN = this.editElementGSTIN.nativeElement.value;
    if(this.hasShortCode)
      this.elementArr[this.editingIndex].shortCode = this.editElementShortCode.nativeElement.value;
    this.isAdding = true;
    this.isEditing = false;
    this.editingIndex = null;
    this.changesMade = true;
    this.elementChangedEmitter.emit();
  }

  goDelete(index){
    let confirm = window.confirm("Are you sure you want to delete this "+ this.elementType + "?");
    if(confirm){
      if(this.editingIndex > -1){
          this.isAdding = true;
          this.isEditing = false;
          this.editingIndex = null;
      }
      this.elementArr.splice(index,1);
      this.changesMade = true;
      this.elementChangedEmitter.emit();
    }
  }

  isEditingCard(index){
    return this.editingIndex == index;
  }
}
