import { Component} from '@angular/core';


@Component({
  selector: 'app-print-layout',
  templateUrl: './printLayout.component.html',
  styleUrls: ['./printLayout.component.css']
})

export class PrintLayoutComponent {

  
  
  handleClick(contentType: any) {

  }
}
