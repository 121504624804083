import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DBAccessor } from '../../db/DBAccessor';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app/app.component';
import { MatSort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'ro-listpage',
  templateUrl: './ROListPage.component.html',
  styleUrls: ['./ROListPage.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ROListPageComponent {

    searchHidden = true;
    snackBar
    allReleaseOrdersArray = []
    RODataSource = new MatTableDataSource(this.allReleaseOrdersArray);
    allColumnDefinitions = ['rowNum','billNo','clientName','billDate','insertionDate','publicationsStr','releaseType','isInvoiceGenerated','netAmount','actions']
    
    @ViewChild(MatSort, {static: true}) sortM: MatSort;
    @ViewChild('ROListTable', {static: false}) ROListTableElement: MatTable<any>;
    

    constructor(private dbAccessor: DBAccessor, private _snackBar: MatSnackBar, private appComponent: AppComponent,
       private titleService: Title){
      //this.searchHidden = true;
      this.titleService.setTitle("Release Orders");
      this.loadContents();
      
    }

    loadContents(){
      this.appComponent.setScreenBusyState(true);
      this.dbAccessor.retrieveAllByType("RO").then((res) => {
        let displayDocsArr = [];
        if(res.success){
          res.docs.forEach((resDoc)=>{
            let displayDoc : any = {
              id : resDoc._id,
              rev : resDoc._rev,
              billNo : resDoc.header.billNo,
              billDate : resDoc.header.billDate,
              clientName : resDoc.header.client.name,
              isInvoiceGenerated : resDoc.header.isInvoiceGenerated,
              netAmount : resDoc.totalObj && resDoc.totalObj.netAmount || 0
            };
            let insertionDates = new Set();
            let publicationSet = new Set();
            
            // Get Insertions total count
            let totalInsertionCount = 0;
            let isTextDate = false;
            resDoc.items.forEach((resDocItem) => {
                let newItemDate : Date;
                if(resDoc.header.releaseType == "print" || resDoc.header.releaseType == "printClassified" || resDoc.header.releaseType == "printFreeform"){
                  if((!resDoc.columnHeaderDetails || !resDoc.columnHeaderDetails['insertionDate'])
                    || !resDoc.columnHeaderDetails['insertionDate'].isCustom)
                    newItemDate = new Date(resDocItem.insertionDate);
                  else{
                    isTextDate = true;
                    newItemDate = null;
                  }
                }
                else if(resDoc.header.releaseType == "radio" || resDoc.header.releaseType == "others"){
                  if(!isTextDate){
                    if(resDocItem.campaignDate instanceof Date && !isNaN(resDocItem.campaignDate))
                      newItemDate = new Date(resDocItem.campaignDate);  
                    else{
                      isTextDate = true;
                      newItemDate = null;
                    }
                  }
                  else
                    newItemDate = null;
                }
                else newItemDate = null;
                if(newItemDate)
                  insertionDates.add(newItemDate);
                publicationSet.add(resDocItem.publication);
                totalInsertionCount += resDocItem.insertionCount;
            });

            // Get insertion date range
            if(!isTextDate){
              let maxItemDate = new Date(Math.max.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let minItemDate = new Date(Math.min.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let itemDateRangeStr;
              if(maxItemDate === minItemDate)
                itemDateRangeStr = minItemDate;
              else
                itemDateRangeStr = minItemDate + ' - ' + maxItemDate;
              displayDoc.itemDateRangeStr = itemDateRangeStr;
            }
            else
              displayDoc.itemDateRangeStr = resDoc.items[0].campaignDateStr;

            // Release Type
            let outReleaseType: string;
            if(resDoc.header.releaseType == "print")
              displayDoc.releaseType = "Print";
            else if(resDoc.header.releaseType == "printClassified")
                displayDoc.releaseType = "Print (Classified)";
            else if(resDoc.header.releaseType == "printFreeform")
                displayDoc.releaseType = "Print (Freeform)";
            else if(resDoc.header.releaseType == "radio")
              displayDoc.releaseType = "Radio";
            else if(resDoc.header.releaseType == "others")
              displayDoc.releaseType = "Others";

            // Put total insertion count
            displayDoc.insertionCount = totalInsertionCount;

            // Put publication list
            let publicationsStr;
            let publicationsArr = Array.from(publicationSet);
            if(publicationSet.size == 1)
              publicationsStr = publicationsArr[0];
            else
              publicationsStr = publicationsArr[0] + '(+ ' + (publicationsArr.length - 1) + ' others)';
            displayDoc.publicationsStr = publicationsStr;
            displayDocsArr.push(displayDoc);
          });
        }
        this.allReleaseOrdersArray = displayDocsArr;
        this.RODataSource.data = this.allReleaseOrdersArray;
        this.RODataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'billNo':
              let finYearStr, finYear, serialNumStr, serialNum;
              let formatType = "";
              if(item[property].split('/')[0] == "ES"){ // New convention
                finYearStr = item[property].split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                let serialNumStrArr = item[property].split('/');
                serialNum = parseInt(serialNumStrArr[serialNumStrArr.length - 1]);
                formatType = "new";
              }
              else{
                finYearStr = item[property].split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                serialNumStr = item[property].split('/')[0] || "ES1";
                serialNum = parseInt(serialNumStr.split('ES')[1]);
                formatType = "old";
              }
              let priority = finYear * 2000 + serialNum;
              if(priority == null || isNaN(priority))
                priority = 0;
              else if(formatType == "new")
                priority += 2000;
              return priority;
            default: return item[property];
          }
        };
        this.RODataSource.sort = this.sortM;
        this.appComponent.setScreenBusyState(false);
      }); 
    }

    applySearch(value){

    }

    onDeleteButtonClick(event, row){
      let confirmStatus = confirm("Are you sure you want to delete this RO?");
      if(confirmStatus){
        return this.dbAccessor.remove(row.id, row.rev).then((res) => {
          if(res.success){
            this._snackBar.open("Successfully deleted RO : " + res.id, "", {
              duration: 3000,
            });
            this.loadContents();
          }
          else{
            this._snackBar.open("Failed to delete RO. Error : " + res.error, "", {
              duration: 3000,
            });
          }
        })
        
      }
    }

    onRefreshClick(){
      this.loadContents();
      //this.ROListTableElement.renderRows();
    }
    
}
