import { Component, ViewChild, Input, EventEmitter, Output, AfterViewInit, OnInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { RadioItem } from '../../../ItemTypes/RadioItem';
import { MatTable } from '@angular/material/table';

@Component({
  selector: '[RadioItemTable]',
  templateUrl: './RadioItemTable.component.html',
  styleUrls: ['./RadioItemTable.component.css']
})

export class RadioItemTableComponent implements OnInit, AfterViewInit{
  radioItems : RadioItem[] = []
  public currDate = new Date();
  public topPublicationShortCode = "";

  publicationSearchValue = '';
  freqPublicationsListFiltered = [];

  pubSearchInput: ElementRef;
  
  @Input() createMode : Boolean = true
  @Input() editMode : Boolean = true
  @Input() printMode : Boolean = false

  @Input() freqPublicationsList = []

  @Output() publicationTopModified = new EventEmitter();
  @Output() itemTotalChange = new EventEmitter();
 

  RadioItemColumnsWithoutActions = ['campaignDate','publication','insertionCount','caption','rate','jingleLength','billingUnitCount','runsPerDay','comment','amount']
  RadioItemColumns = [];

  columnHeaderDetails = {
    'comment' : {
      isVisible: true,
      text: "Comment"
    }
  }
  
  @ViewChild('pubSearchInput', {static: false}) set pubSearchInputSet(content: ElementRef) {
    this.pubSearchInput = content;     
  }

  @ViewChild('radioItemsTable', {static: false}) radioItemsTableElement: MatTable<any>;
  
  constructor(private cdRef:ChangeDetectorRef){
    return this;
  }
  
  ngOnInit(){
    this.refreshColumnHeaders();
    this.freqPublicationsListFiltered = this.freqPublicationsList;
  }

  refreshColumnHeaders() {
    this.RadioItemColumns = [];
    if(!this.printMode){
      this.RadioItemColumns = Array.from(this.RadioItemColumnsWithoutActions);
      this.RadioItemColumns.push('deleteBtn');
    }
    else{ // print mode, hide invisible columns
      this.RadioItemColumnsWithoutActions.forEach((columnID) => {
        if(!this.columnHeaderDetails[columnID] || this.columnHeaderDetails[columnID].isVisible){
          this.RadioItemColumns.push(columnID);
        }
      })
    }
  }

  ngAfterViewInit(){
    //this.refreshColumnHeaders();
    if(this.createMode && this.radioItems.length == 0){
      this.addItem(new RadioItem(null));
    }
      
    this.cdRef.detectChanges();
  }

  addItem(newRadioItem : RadioItem){
    this.radioItems.push(newRadioItem);
    this.refreshItemTable();
    
  }
  onDeleteButtonClick(event, row){
    let confirmResult = confirm("Are you sure you want to delete this item?");
    if(confirmResult)
      this.deleteItem(row);
  }

  deleteItem(delRow){
    let foundIndex = this.radioItems.findIndex((radioItemRow) => radioItemRow == delRow);
    if(foundIndex > -1){
      this.radioItems.splice(foundIndex,1);
      this.refreshItemTable();
      this.itemTotalChange.emit(this.radioItems);
    }
    if(this.radioItems.length == 0){
      this.topPublicationShortCode = "";
      this.publicationTopModified.emit(this.topPublicationShortCode);
    }
    
  }

  getColumnHeaderDetails(){
    return this.columnHeaderDetails;
  }

  setColumnHeaderDetails(inHeaderDetails){
    Object.keys(inHeaderDetails).forEach((columnID) => {
      this.columnHeaderDetails[columnID] = inHeaderDetails[columnID];
    });
    
    this.refreshColumnHeaders();
  }

  calculateAmount(row){
    row.calculateBillingUnits();
    row.calculateAmount();
    this.itemTotalChange.emit(this.radioItems);
  }

  clearItems(){
    this.radioItems = [];
    this.refreshItemTable();
    this.itemTotalChange.emit(this.radioItems);
    this.publicationTopModified.emit("");
  }

  refreshItemTable(){
    this.radioItemsTableElement.renderRows();
  }

  getItemsArray(){
    return this.radioItems;
  }

  filterPublications($event){
    if(!$event.srcElement.value || $event.srcElement.value.length == 0)
      this.freqPublicationsListFiltered = this.freqPublicationsList;
    else
      this.freqPublicationsListFiltered = this.freqPublicationsList.filter((el) => el.name.toLowerCase().includes($event.srcElement.value.toLowerCase()) 
        || (el.shortCode && el.shortCode.toLowerCase().includes($event.srcElement.value.toLowerCase())));
  }

  publicationMenuOpened(){
    setTimeout(() => {
      this.pubSearchInput.nativeElement.focus();
    });
  }

  onPublicationsListItemClick(index, publication){
    this.radioItems[index].publication = publication.name;
    this.radioItems[index].publicationShortCode = publication.shortCode;
    if(!this.topPublicationShortCode || this.topPublicationShortCode.length == 0){
      this.topPublicationShortCode = publication.shortCode;
      this.publicationTopModified.emit(this.topPublicationShortCode);
    }
  }
}
