import { Pipe, PipeTransform } from '@angular/core';
const DateUtil = require('./DateUtil.js');

@Pipe({
  name: 'dateTextPipe',
  pure: true
})
export class DateTextTransformPipe implements PipeTransform {
  transform(date: Date): any {
      return DateUtil.getDateText(date);
  }
}