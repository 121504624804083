import { Injectable, ɵSWITCH_RENDERER2_FACTORY__POST_R3__ } from '@angular/core';
import { ImportHelper } from './ImportHelper';
const DateUtil = require('../util/DateUtil.js');
//const brotli = require('brotli');
import LZString from 'lz-string';
import  PouchDB from 'pouchdb/dist/pouchdb';
import PouchDBFind  from 'pouchdb-find';
import { ElementSchemaRegistry } from '@angular/compiler';
PouchDB.plugin(PouchDBFind);

@Injectable({    
    providedIn: 'root'    
  })   
export class DBAccessor {

  
    db = new PouchDB('billGenDB');
    importHelperUtil = new ImportHelper()

    checkAndInitConfig(){
        let newConfigFile = {
            type: 'config',
            nextRO_num : 1,
            nextInvoice_num : 1,
            nextInvoiceDraft_num : 1,
            nextProforma_num : 1,
            nextProformaDraft_num : 1,
            freqAddressees : [],
            freqClients : [],
            freqPublications : [],
            dbCode : "",
            lastModified : ""
        };
        return this.getConfig().then((config) => {
            if(!config){
                return this.store(newConfigFile);
            }
            else{
                let isChanged = false;
                if(!config.nextInvoiceDraft_num){
                    config.nextInvoiceDraft_num = 1;
                    isChanged = true;
                }
                if(!config.nextProforma_num){
                    config.nextProforma_num = 1;
                    isChanged = true;
                }
                if(!config.nextProformaDraft_num){
                    config.nextProformaDraft_num = 1;
                    isChanged = true;
                }
                if(isChanged){
                    this.update(config);
                }
                else return null;
            }
        })

    }
    
    getConfig(){
        return this.retrieveAllByType('config').then((res) => {
            if(res.success){
                return res.docs[0];
            }
            else
                return null; // error
        })
    }

    getNextDocNum(docType){
        return this.getConfig().then((configFile) => {
            let resNum;
            if(!configFile)
                return null;
            if(docType == 'RO')
                resNum = configFile.nextRO_num || 1;
            else if(docType == 'Invoice')
                resNum = configFile.nextInvoice_num || 1;
            else if(docType == 'InvoiceDraft')
                resNum = configFile.nextInvoiceDraft_num || 1;
            else if(docType == 'Proforma')
                resNum = configFile.nextProforma_num || 1;
            else if(docType == 'ProformaDraft')
                resNum = configFile.nextProformaDraft_num || 1;
            return resNum;
        });
    }

    incrementNum(docType){
        return this.getConfig().then((configFile) => {
            if(configFile){
                if(docType == "RO"){
                    configFile.nextRO_num++;
                }
                else if(docType == "Invoice"){
                    configFile.nextInvoice_num++;
                }
                else if(docType == "InvoiceDraft"){
                    configFile.nextInvoiceDraft_num++;
                }
                else if(docType == "Proforma"){
                    configFile.nextProforma_num++;
                }
                else if(docType == "ProformaDraft"){
                    configFile.nextProformaDraft_num++;
                }
                if(docType == "RO" || docType == "Invoice" || docType == "InvoiceDraft" || docType == "Proforma" || docType == "ProformaDraft"){
                    return this.update(configFile);
                }
            }
            else return null;
        });
    }

    getByID(id){
        return this.db.get(id).then((doc) => {
            return doc;
        })
    }

    retrieve(queryParams){
        let queryParamsObj = {};
        queryParamsObj["selector"] = queryParams.findParam;
        if(queryParams.fieldsToReturn)
            queryParamsObj["fields"] = queryParams.fieldsToReturn;
        if(queryParams.fieldsToSort)
            queryParamsObj["sort"] = queryParams.fieldsToSort;
        return this.db.find(queryParamsObj).then((result) => {
            return {
                success : true,
                docs : result.docs
            }
        }).catch((err) => {
            return {
                success : false,
                error : err
            }
        });
    }

    store(storeObj){
        return this.db.post(storeObj).then((res) => {
            if(res.ok){
                return { 
                    success : true,
                    createdID : res.id,
                    createdRev : res.rev
                };
            }
            else return {
                success : false
            };
        }).catch((err) => {
            return {
                success : false,
                error : err
            };
        });
    }

    update(updateObj){
        return this.db.put(updateObj).then((res) => {
            if(res.ok){
                return { 
                    success : true,
                    updatedID : res.id,
                    updatedRev : res.rev
                };
            }
            else return {
                success : false
            };
        }).catch((err) => {
            return {
                success : false,
                error : err
            };
        });
    }

    remove(docID, docRev){
        return this.db.remove(docID, docRev).then((res) => {
            return {
                success : res.ok,
                id : res.id
            }
        }).catch((err) => {
            return {
                success : false,
                error : err
            }
        });
    }

    retrieveAllByType(docType, fields=null){
        let fieldsArr;
        if(fields) 
            fieldsArr = fields;
        else 
            fieldsArr = null;
        return this.retrieve({
            findParam: {
                type: {$eq: docType}
            },
            fieldsToReturn : fieldsArr
        });
    }

    importDBFromFile(fileToImport : File){
        let fileImportPromise:any = Promise.resolve();
        if(fileToImport){
            fileImportPromise = new Promise((resolve,reject) => {
                let DBFileReader = new FileReader();
                DBFileReader.onload = (event: any) => {
                    //let fileContentsObj = JSON.parse(LZString.decompressFromUTF16(event.target.result));
                    let fileContentsObj = JSON.parse(event.target.result);
                    //let fileContentsObj = this.importHelperUtil.processFileToImport(fileContentsObjIn);
                    //console.log(fileContents);
                    this.db.bulkDocs(
                      fileContentsObj,
                      {new_edits: false}, // not change revision
                      (err,res) => {
                          console.log('DONE', res);
                          resolve({"ok":true});
                      }
                    );
                  }
                  DBFileReader.onerror = (event) => {
                    console.error("Unable to read file");
                    // Maybe report to UI
                    reject(event);
                  }
                  DBFileReader.readAsText(fileToImport);
            });
        }
        return fileImportPromise;
    }

    
    exportDBToFile(){
        let fileExportPromise = new Promise((resolve,reject) => {
            this.db.allDocs({include_docs: true}, (error, doc) => {
                if (error){
                    console.error(error);
                    reject({"ok":false, "error":error});
                } 
                else{
                    let currDateTime = new Date();
                    let currDateStr = '' + String(currDateTime.getDate()).padStart(2, "0") + String(currDateTime.getMonth()).padStart(2, "0") 
                                        + currDateTime.getFullYear();
                    let currTimeStr = '' + String(currDateTime.getHours()).padStart(2, "0") + String(currDateTime.getMinutes()).padStart(2, "0")
                                        + String(currDateTime.getSeconds()).padStart(2, "0");
                    let outFileName = 'databaseBackup' + currDateStr + '_' + currTimeStr + '.dat';
                    //let brotliOptions = { mode : 1 };
                    let outRowsStr = JSON.stringify(doc.rows.map(({doc}) => doc));
                    //let zippedStr = LZString.compressToUTF16(outRowsStr);
                    this.download(
                        outRowsStr,
                        outFileName,
                        'text/plain'
                        );
                    resolve({"ok":true, "filename":outFileName});
                } 
              });
        });
        return fileExportPromise;
    }

    updateLastModifiedAndTakeBackup() {
        return this.getConfig().then((configFile) => {
            configFile.lastModified = DateUtil.toMysqlFormat(new Date());
            return this.update(configFile);
        }).then((result) => {
            if(result.success){
                return this.performRemoteBackup();
            }
            else return Promise.reject();
        });
    }

    performRemoteBackup() {
        let fileExportPromise = new Promise((resolve,reject) => {
            this.getConfig().then((config) => {
                this.db.allDocs({include_docs: true}, (error, doc) => {
                    if (error){
                        console.error(error);
                        reject({"success":false, "error":error});
                    } 
                    else{
                        let outRowsCompressedStr = LZString.compressToUTF16(JSON.stringify(doc.rows));
                        let outObj = {
                            lastModified : config.lastModified,
                            dbCode : config.dbCode,
                            config : config,
                            rows : outRowsCompressedStr
                        };
                        let outObjStr =  JSON.stringify(outObj);
                        
                        return fetch("https://billgen.netimes.news/performBackup.php", {
                            // Adding method type
                            method: "POST",
                            
                            // Adding body or contents to send
                            body: outObjStr,
                            
                            // Adding headers to the request
                            headers: {
                                "Content-type": "application/json"
                            }
                        })
                        
                        // Converting to JSON
                        .then(response =>{
                            return response.json();
                        }).then((resJson) => {
                            if(resJson.success)
                                resolve({"success":true});
                            else
                                reject({"success":false, "error": resJson.error});
                        })
                        .catch(error => {
                            console.error(error);
                            reject({"success":false, "error":error});
                        });
                    }
                });
            });
            
        });
        return fileExportPromise;
    }
/*
    exportDBToRemoteBackup(){
        let fileExportPromise = new Promise((resolve,reject) => {
            this.db.allDocs({include_docs: true}, (error, doc) => {
                if (error){
                    console.error(error);
                    reject({"ok":false, "error":error});
                } 
                else{
                    let outDateTime = DateUtil.toMysqlFormat(new Date());
                    let deployType;
                    if(os.hostname == "localhost")
                        deployType = "TEST";
                    else
                        deployType = "PROD";
                    let outData = JSON.stringify(doc.rows.map(({doc}) => doc));
                    let con = this.getRemoteDBConnection();
                    resolve();
                   // resolve({"ok":true, "filename":outFileName});
                } 
              });
        });
        return fileExportPromise;
    }
*/
    clearDB(){
        let fileExportPromise = new Promise((resolve,reject) => {
            this.db.destroy(function (err, response) {
                if (err) {
                   console.log(err);
                   reject(err);
                } else {
                   console.log("Database Deleted");
                   this.db = new PouchDB('billGenDB');
                   resolve();
                }
            }.bind(this));

        });
        return fileExportPromise;
    }

    download(data, filename, type) {
        var file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function() {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);  
            }, 0); 
        }
    }
}