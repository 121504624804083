import { Component, ViewChild, Input, AfterViewInit, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { OthersItem } from '../../../ItemTypes/OthersItem';
import { MatTable } from '@angular/material/table';

@Component({
  selector: '[OthersItemTable]',
  templateUrl: './OthersItemTable.component.html',
  styleUrls: ['./OthersItemTable.component.css']
})

export class OthersItemTableComponent implements OnInit, AfterViewInit{
  othersItems : OthersItem[] = []
  public currDate = new Date();

  @Input() createMode : Boolean = true
  @Input() editMode : Boolean = true
  @Input() printMode : Boolean = false

  @Input() freqPublicationsList = []

  @Output() itemTotalChange = new EventEmitter();

  OthersItemColumnsWithoutActions = ['rowNum','description','amount']
  OthersItemColumns = []

  columnHeaderDetails = {
  }

  @ViewChild('othersItemsTable', {static: false}) othersItemsTableElement: MatTable<any>;
  
  constructor(private cdRef:ChangeDetectorRef){
    return this;
  }
  
  ngOnInit(){
    this.OthersItemColumns = Array.from(this.OthersItemColumnsWithoutActions);
    if(!this.printMode){
      this.OthersItemColumns.push('deleteBtn');
    }
  }
  
  ngAfterViewInit(){
    if(this.createMode && this.othersItems.length == 0){
      this.addItem(new OthersItem(null));
    }
      
    this.cdRef.detectChanges();
  }

  addItem(newOthersItem : OthersItem){
    this.othersItems.push(newOthersItem);
    this.refreshItemTable();
    
  }

  updateAmount(row){
    this.itemTotalChange.emit(this.othersItems);
  }
  onDeleteButtonClick(event, row){
    let confirmResult = confirm("Are you sure you want to delete this item?");
    if(confirmResult)
      this.deleteItem(row);
  }
/*
  verifyAmount(){
    return isNaN(this.amount);
  }
*/
  deleteItem(delRow){
    let foundIndex = this.othersItems.findIndex((printItemRow) => printItemRow == delRow);
    if(foundIndex > -1){
      this.othersItems.splice(foundIndex,1);
      this.refreshItemTable();
      this.itemTotalChange.emit(this.othersItems);
    }
    
  }

  getColumnHeaderDetails(){
    return this.columnHeaderDetails;
  }

  setColumnHeaderDetails(inHeaderDetails){
    Object.keys(inHeaderDetails).forEach((columnID) => {
      this.columnHeaderDetails[columnID] = inHeaderDetails[columnID];
    })
  }

  clearItems(){
    this.othersItems = [];
    this.refreshItemTable();
    this.itemTotalChange.emit(this.othersItems);
  }

  refreshItemTable(){
    this.othersItemsTableElement.renderRows();
  }

  getItemsArray(){
    return this.othersItems;
  }
  

}
