import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ControlsMaterialModule } from '../material-module';
//import { DBAccessor } from '../db/DBAccessor';

//import { } from '../forms/Invoice/Invoice.component';
import { ROFormComponent } from '../forms/RO/ROForm.component';
import { InvoiceFormComponent } from '../forms/Invoice/InvoiceForm.component';

import { PrintItemTableComponent } from '../forms/ItemTables/Print/PrintItemTable.component'
import { RadioItemTableComponent } from '../forms/ItemTables/Radio/RadioItemTable.component'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ROListPageComponent } from '../listPages/RO/ROListPage.component';
import { BackupDBComponent } from '../db/BackupDBComponent/BackupDBPage.component';
import { ConfigPageComponent }from '../db/ConfigPageComponent/ConfigPage.component';
import { ConfigElementListComponent } from '../db/ConfigPageComponent/ConfigElementListComponent/ConfigElementList.component';

import { PrintLayoutComponent } from '../printPages/printLayout/printLayout.component';
import { ROPrintPageComponent } from '../printPages/RO/ROPrintPage.component';
import { InvoicePrintPageComponent } from '../printPages/Invoice/InvoicePrintPage.component';
import { PrintService } from '../printPages/printService.service';
import { NewInvoiceService } from '../forms/Invoice/NewInvoiceService.service';
import { InvoiceListPageComponent } from '../listPages/Invoice/InvoiceListPage.component';
import { ProformaListPageComponent } from '../listPages/Proforma/ProformaListPage.component';
import { GSTListPageComponent } from '../listPages/GST/GSTListPage.component';

import { DateTextTransformPipe } from '../util/DateTextTransform.pipe';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PrintClassifiedItemTableComponent } from '../forms/ItemTables/PrintClassified/PrintClassifiedItemTable.component';
import { DateLocaleTransformPipe } from '../util/DateLocaleTransform.pipe';
import { PrintFreeformItemTableComponent } from '../forms/ItemTables/PrintFreeform/PrintFreeformItemTable.component';
import { OthersItemTableComponent } from '../forms/ItemTables/Others/OthersItemTable.component';
import { CustomerReportPageComponent } from '../reportPages/CustomerReportPage/CustomerReportPage.component';
import { MonthReportPageComponent } from '../reportPages/MonthReportPage/MonthReportPage.component';
import { ROReportPageComponent } from '../reportPages/ROReportPage/ROReportPage.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfigPageComponent,
    ConfigElementListComponent,
    ROFormComponent,
    InvoiceFormComponent,
    ROListPageComponent,
    InvoiceListPageComponent,
    ProformaListPageComponent,
    GSTListPageComponent,
    PrintItemTableComponent,
    PrintClassifiedItemTableComponent,
    PrintFreeformItemTableComponent,
    RadioItemTableComponent,
    OthersItemTableComponent,
    BackupDBComponent,
    PrintLayoutComponent,
    ROPrintPageComponent,
    InvoicePrintPageComponent,
    CustomerReportPageComponent,
    MonthReportPageComponent,
    DateTextTransformPipe,
    DateLocaleTransformPipe,
    ROReportPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ControlsMaterialModule,
    FormsModule 
  ],
  providers: [PrintService, NewInvoiceService, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
