import { Component, ViewChild, ViewEncapsulation, AfterViewInit, OnInit } from '@angular/core';
import { DBAccessor } from '../../db/DBAccessor';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app/app.component';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'proforma-listpage',
  templateUrl: './ProformaListPage.component.html',
  styleUrls: ['./ProformaListPage.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ProformaListPageComponent implements OnInit{

    searchHidden = true;
    showDrafts = false;
    snackBar
    allProformasArray = []
    proformasDataSource = new MatTableDataSource(this.allProformasArray);
    subscriptionContainer = new Subscription();
    newObjRouterLink = "create";
    
    allColumnDefinitions = ['rowNum','billNo','clientName','billDate','insertionDateRange','publicationsStr','releaseType','netAmount','actions']
    
    
    @ViewChild(MatSort, {static: true}) sortM: MatSort;
    @ViewChild('ProformaListTable', {static: false}) InvoiceListTableElement: MatTable<any>;
    

    constructor(private route: ActivatedRoute, private dbAccessor: DBAccessor, private _snackBar: MatSnackBar, 
                private appComponent: AppComponent, private titleService: Title){
      //this.searchHidden = true;
      this.titleService.setTitle("Proformas");
    }

    ngOnInit(){
      this.subscriptionContainer.add(this.route.data.subscribe((data) => {
        this.showDrafts = data.showDrafts;
        if(this.showDrafts){
          this.newObjRouterLink = '../Proforma/create';
        }
        this.loadContents();
      }));
    }

    loadContents(){
      this.appComponent.setScreenBusyState(true);
      this.dbAccessor.retrieveAllByType("Proforma").then((res) => {
        let displayDocsArr = [];
        if(res.success){
          res.docs.forEach((resDoc)=>{
            if(this.showDrafts != resDoc.header.isDraft){ // Filtering drafts according to setting
              return;
            }
            let displayDoc : any = {
              id : resDoc._id,
              rev : resDoc._rev,
              billNo : resDoc.header.billNo,
              billDate : resDoc.header.billDate,
              clientName : resDoc.header.addressee.name,
              netAmount : resDoc.totalObj && resDoc.totalObj.netAmount || 0
            };
            let insertionDates = new Set();
            let publicationSet = new Set();

            // Mark drafts
            if(resDoc.header.isDraft){
              displayDoc.billNo = displayDoc.billNo + ' [Draft]'
            }
            
            // Get Insertions total count
            let totalInsertionCount = 0;
            let isTextDate = false;
            resDoc.items.forEach((resDocItem) => {
                let newItemDate : Date;
                if(resDoc.header.releaseType == "print" || resDoc.header.releaseType == "printClassified" || resDoc.header.releaseType == "printFreeform"){
                  if((!resDoc.columnHeaderDetails || !resDoc.columnHeaderDetails['insertionDate'])
                         || !resDoc.columnHeaderDetails['insertionDate'].isCustom)
                    newItemDate = new Date(resDocItem.insertionDate);
                  else{
                    isTextDate = true;
                    newItemDate = null;
                  }
                }
                else if(resDoc.header.releaseType == "radio" || resDoc.header.releaseType == "others"){
                  if(!isTextDate){
                    if(resDocItem.campaignDate instanceof Date && !isNaN(resDocItem.campaignDate))
                      newItemDate = new Date(resDocItem.campaignDate);  
                    else{
                      isTextDate = true;
                      newItemDate = null;
                    }
                  }
                  else
                    newItemDate = null;
                }
                else newItemDate = null;
                if(newItemDate)
                  insertionDates.add(newItemDate);
                publicationSet.add(resDocItem.publication);
                totalInsertionCount += resDocItem.insertionCount;
            });

            // Get insertion date range
            if(!isTextDate){
              let maxItemDate = new Date(Math.max.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let minItemDate = new Date(Math.min.apply(null,Array.from(insertionDates))).toLocaleDateString("en-IN");
              let itemDateRangeStr;
              if(maxItemDate === minItemDate)
                itemDateRangeStr = minItemDate;
              else
                itemDateRangeStr = minItemDate + ' - ' + maxItemDate;
              displayDoc.itemDateRangeStr = itemDateRangeStr;
            }
            else
              displayDoc.itemDateRangeStr = resDoc.items[0].campaignDateStr;

            // Release Type
            let outReleaseType: string;
            if(resDoc.header.releaseType == "print")
              displayDoc.releaseType = "Print";
            else if(resDoc.header.releaseType == "printClassified")
                displayDoc.releaseType = "Print (Classified)";
            else if(resDoc.header.releaseType == "printFreeform")
                displayDoc.releaseType = "Print (Freeform)";
            else if(resDoc.header.releaseType == "radio")
              displayDoc.releaseType = "Radio";
            else if(resDoc.header.releaseType == "others")
              displayDoc.releaseType = "Others";

            // Put total insertion count
            displayDoc.insertionCount = totalInsertionCount;

            // Put publication list
            let publicationsStr;
            let publicationsArr = Array.from(publicationSet);
            if(publicationSet.size == 1)
              publicationsStr = publicationsArr[0];
            else
              publicationsStr = publicationsArr[0] + '(+ ' + (publicationsArr.length - 1) + ' others)';
            displayDoc.publicationsStr = publicationsStr;
            displayDocsArr.push(displayDoc);
          });
        }
        this.allProformasArray = displayDocsArr;
        //this.InvoiceListTableElement.renderRows();
        this.proformasDataSource.data = this.allProformasArray;
        this.proformasDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'billNo':
              let finYearStr, finYear, serialNumStr, serialNum;
              let IDStr = item[property].split(' ')[0];
              if(IDStr.split('/')[0] == "ES"){ // New convention
                finYearStr = IDStr.split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                let serialNumStrArr = IDStr.split('/');
                serialNum = parseInt(serialNumStrArr[serialNumStrArr.length - 1])
              }
              else{
                finYearStr = IDStr.split('/')[1] || "20-21";
                finYear = parseInt(finYearStr.split('-')[0]);
                serialNumStr = IDStr.split('/')[0] || "ES-1";
                serialNum = parseInt(serialNumStr.split('ES-PRF-')[1]);
              }
              if(finYear == null || isNaN(finYear) || serialNum == null || isNaN(serialNum))
                return 0;
              else
                return finYear * 2000 + serialNum;
            default: return item[property];
          }
        };
        this.proformasDataSource.sort = this.sortM;
        this.appComponent.setScreenBusyState(false);
      }); 
    }

    applySearch(value){

    }

    onDeleteButtonClick(event, row){
      let confirmStatus = confirm("Are you sure you want to delete this Proforma?");
      if(confirmStatus){
        return this.dbAccessor.remove(row.id, row.rev).then((res) => {
          if(res.success){
            this._snackBar.open("Successfully deleted Proforma : " + res.id, "", {
              duration: 3000,
            });
            this.loadContents();
          }
          else{
            this._snackBar.open("Failed to delete Proforma. Error : " + res.error, "", {
              duration: 3000,
            });
          }
        })
        
      }
    }

    onRefreshClick(){
      this.loadContents();
      //this.ROListTableElement.renderRows();
    }
    
}
