import { Component } from '@angular/core';
import { DBAccessor } from '../DBAccessor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'BackupDBComponent',
  templateUrl: './BackupDBPage.component.html',
  styleUrls: ['./BackupDBPage.component.css']
})

export class BackupDBComponent {
  fileToImport: File = null;

  constructor(private dbAccessor : DBAccessor, private _snackBar : MatSnackBar, private titleService: Title){
    this.titleService.setTitle("Backup");
  }

  importDBFromFile(){
    return this.dbAccessor.importDBFromFile(this.fileToImport).then((res)=> {
      if(res.ok){
        this._snackBar.open("Imported successfully", "", {
          duration: 3000,
        });
      }
      else throw(new Error("Unknown error"));
    }).catch((err)=> {
      this._snackBar.open("Errors occurred while importing: " + err, "", {
        duration: 3000,
      });
    })
  }
  
  exportDBToFile(){
    return this.dbAccessor.exportDBToFile().then((res:any) => {
      if(res.ok){
        this._snackBar.open("Exported successfully to file name: " + res.filename, "", {
          duration: 3000,
        });
      }
      else throw(new Error("Unknown error"));
      
    }).catch((errObj) => {
      this._snackBar.open("Errors occurred while exporting: " + errObj.error, "", {
        duration: 3000,
      });
    });
  }

  remoteBackup(){
    return this.dbAccessor.performRemoteBackup().then((res:any) => {
      if(res.ok){
        this._snackBar.open("Remote backup successful", "", {
          duration: 3000,
        });
      }
      else throw(new Error("Unknown error"));
    }).catch((errObj) => {
      this._snackBar.open("Errors occurred while making remote backup: " + errObj.error, "", {
        duration: 3000,
      });
    });
  }

  clearDB(){
    let confResult = confirm("Are you sure you want to clear the database?\nWarning: This cannot be undone.");
    if(confResult){
      return this.dbAccessor.clearDB().then((res) => {
        this._snackBar.open("Database successfully cleared.", "", {
          duration: 3000,
        });
      }).catch((err) => {
        this._snackBar.open("Errors occurred while clearing database: " + err, "", {
          duration: 3000,
        });
      })
    }
  }


  handleFileInput(files){
    if(files.length > 0)
      this.fileToImport = files.item(0);
    else
      this.fileToImport = null;
  }
}
