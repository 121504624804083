import { Component } from '@angular/core';
import { DBAccessor } from '../DBAccessor';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigElementListComponent } from './ConfigElementListComponent/ConfigElementList.component'
import { Router, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription, config } from 'rxjs';
import { Title } from '@angular/platform-browser';
import DateUtil from '../../util/DateUtil.js';

@Component({
  selector: 'ConfigPageComponent',
  templateUrl: './ConfigPage.component.html',
  styleUrls: ['./ConfigPage.component.css']
})

export class ConfigPageComponent {
  freqAddressees = []
  freqClients = []
  freqPublications = []

  nextRONum = 1;
  nextInvoiceNum = 1;
  nextInvoiceDraftNum = 1;
  nextProformaNum = 1;
  nextProformaDraftNum = 1;

  dbCode = "";
  lastModified = "";

  configFile

  isSaving = false
  isRefreshing = false
  IDConfigChangesMade = false
  changesMade = false

  dataFetchSubscription = new Subscription();
  navigationSubscription = new Subscription();

  constructor(private dbAccessor : DBAccessor, private _snackBar : MatSnackBar, private router: Router, 
    private titleService: Title){
    /*this.configFile = this.dbAccessor.getConfig();
    //this.freqAddressees = this.configFile.freqAddressees || [];
    this.freqClients = this.configFile.freqClients || [];
    this.freqPublications = this.configFile.freqPublications || [];
    */
   //this.dataFetchSubscription = 
   this.titleService.setTitle("Configuration");
   this.dbAccessor.getConfig().then((configFile) => {
     this.configFile = configFile;
     this.nextRONum = configFile.nextRO_num;
     this.nextInvoiceNum = configFile.nextInvoice_num;
     this.nextInvoiceDraftNum = configFile.nextInvoiceDraft_num;
     this.nextProformaNum = configFile.nextProforma_num;
     this.nextProformaDraftNum = configFile.nextProformaDraft_num;
     this.configFile.freqAddressees = this.configFile.freqAddressees || [];
     this.configFile.freqClients = this.configFile.freqClients || [];
     this.configFile.freqPublications = this.configFile.freqPublications || [];
     this.dbCode = this.configFile.dbCode || "";
     this.lastModified = this.configFile.lastModified || "";
     this.freqAddressees = this.configFile.freqAddressees;
     this.freqClients = this.configFile.freqClients;
     this.freqPublications = this.configFile.freqPublications;
   })
/*
   this.navigationSubscription = this.router.events.subscribe( (event: RouterEvent) => {

      if (event instanceof NavigationStart) {
          // Show loading indicator
          window.alert("Start")
      }
  });*/
  }

  setIDConfigChangeIndicator(){
    this.IDConfigChangesMade = true;
    this.changesMade = true;
  }

  setElementChangeIndicator(event){
    this.changesMade = true;
  }

  goSaveChanges(){
    this.isSaving = true;
    let confirm = window.confirm("Are you sure you want to save your changes?");
    if(confirm){
      this.configFile.nextRO_num = this.nextRONum;
      this.configFile.nextInvoice_num = this.nextInvoiceNum;
      this.configFile.nextInvoiceDraft_num = this.nextInvoiceDraftNum;
      this.configFile.nextProforma_num = this.nextProformaNum;
      this.configFile.nextProformaDraft_num = this.nextProformaDraftNum;
      this.configFile.dbCode = this.dbCode;
      this.configFile.lastModified = DateUtil.toMysqlFormat(new Date());
      return this.dbAccessor.update(this.configFile).then((result) => {
        if(result.success){
          this._snackBar.open("Changes successfully saved", "", {
            duration: 1500,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        else{
          this._snackBar.open("Error: Changes could not be saved", "", {
            duration: 1500,
          });
        }
      });
    }
    this.isSaving = false;
  }

  goCancelChanges(){
    let confirm = window.confirm("Are you sure you want to cancel your changes?");
    if(confirm){
      //this.router.navigate(['/Config']);
      this.isRefreshing = true;
      window.location.reload();
    }
  }

  ngOnDestroy(){
    this.dataFetchSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }
}
