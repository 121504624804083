import { Component, ViewChild, ChangeDetectorRef, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewInvoiceService } from './NewInvoiceService.service';
import { PrintItemTableComponent } from '../ItemTables/Print/PrintItemTable.component';
import { RadioItemTableComponent } from '../ItemTables/Radio/RadioItemTable.component';
import { PrintItem } from '../../ItemTypes/PrintItem';
import { RadioItem } from '../../ItemTypes/RadioItem';
import userConfig from '../../assets/userConfig.json';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DBAccessor } from '../../db/DBAccessor';
import { PrintService } from '../../printPages/printService.service';
import { PrintClassifiedItemTableComponent } from '../ItemTables/PrintClassified/PrintClassifiedItemTable.component';
import { PrintClassifiedItem } from '../../ItemTypes/PrintClassifiedItem';
import { PrintFreeformItemTableComponent } from '../ItemTables/PrintFreeform/PrintFreeformItemTable.component';
import { PrintFreeformItem } from '../../ItemTypes/PrintFreeformItem';
import { OthersItemTableComponent } from '../ItemTables/Others/OthersItemTable.component';
import { OthersItem } from '../../ItemTypes/OthersItem';
import { Title } from '@angular/platform-browser';
const DateUtil = require('../../util/DateUtil.js');
const numWords = require('num-words')

@Component({
  selector: 'invoiceform',
  templateUrl: './InvoiceForm.component.html',
  styleUrls: ['./InvoiceForm.component.css']
})

export class InvoiceFormComponent implements AfterViewInit, OnInit {

  subscriptionContainer = new Subscription();
  createMode: Boolean = true;
  editMode: Boolean = true;
  fromRO: Boolean = false;
  fromInvoice: Boolean = false;

  titleText = "Tax Invoice";
  routerParentPath = "/Invoice";

  isSaving = false;
  isRefreshing = false;
  isUpdating = false;
  isPrinting = false;
  isProforma = false;

  hasDraftModeChanged = false

  nextInvoice_num
  nextInvoiceDraft_num
  nextProforma_num
  nextProformaDraft_num
  currOrNextInvoice_ID
  currOrNextInvoiceDraft_ID
  currOrNextProforma_ID
  currOrNextProformaDraft_ID

  invoice_ID
  invoice_rev
  dataToLoad

  headerObj = {
    addressee: {
      name: "",
      GSTIN: "",
      shortCode: ""
    },
    billNo: "",
    billDate: new Date(),
    releaseType: "",
    GSTIN: "",
    PAN: "",
    HSNCode: "",
    hasNewLetterhead : false,
    isPaymentMade: false,
    hasRemarks: false,
    splitGst: true,
    showRefRO: true,
    isDraft: false,
    isProforma: false,
    remarks: "",
    refRO: {
      refRONum: "",
      refRODate: new Date()
    },
    sourceRO: {
      title: ""
    }
  }

  IDObj = {
    objType : "",
    billNo : "",
    publication : "",
    client : ""
  }

  totalObj = {
    grossAmount: 0,
    additionalComponents: [],
    subtotal: {
      description: "Subtotal",
      value: 0
    },
    CGST: {
      description: "CGST",
      value: 0
    },
    SGST: {
      description: "SGST",
      value: 0
    },
    totalGST: {
      description: "GST",
      value: 0
    },
    netAmount: 0,
    amountInWords: "-"
  }

  addresseeSearchValue = '';
  
  addresseeSearchInput: ElementRef;

  freqAddresseeList = [];
  freqClientList = [];
  freqPublicationsList = [];
  freqAddresseeListFiltered = [];
  
  printItemsTableContainer: PrintItemTableComponent;
  printClassifiedItemsTableContainer: PrintClassifiedItemTableComponent;
  printFreeformItemsTableContainer: PrintFreeformItemTableComponent;
  radioItemsTableContainer: RadioItemTableComponent;
  othersItemsTableContainer: OthersItemTableComponent;


  @ViewChild('addresseeSearchInput', {static: false}) set addresseeSearchInputSet(content: ElementRef) {
    this.addresseeSearchInput = content;     
  }

  @ViewChild('printItemsTableContainer', { static: false }) set printTableContent(content: PrintItemTableComponent) {
    this.printItemsTableContainer = content;
    if (content) {
      //this.dataToLoad = content;
      //this.headerObj.HSNCode = "998363";
      if (this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "print") {
        this.setPrintItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }

    }
  }

  @ViewChild('printClassifiedItemsTableContainer', { static: false }) set printClassifiedTableContent(content: PrintClassifiedItemTableComponent) {
    this.printClassifiedItemsTableContainer = content;
    if (content) {
      //this.dataToLoad = content;
      //this.headerObj.HSNCode = "998363";
      if (this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printClassified") {
        this.setPrintClassifiedItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }

    }
  }

  @ViewChild('printFreeformItemsTableContainer', { static: false }) set printFreeformTableContent(content: PrintFreeformItemTableComponent) {
    this.printFreeformItemsTableContainer = content;
    if (content) {
      //this.dataToLoad = content;
      //this.headerObj.HSNCode = "998363";
      if (this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "printFreeform") {
        this.setPrintFreeformItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }

    }
  }

  @ViewChild('radioItemsTableContainer', { static: false }) set radioTablecontent(content: RadioItemTableComponent) {
    this.radioItemsTableContainer = content;
    if (content) {
      //this.dataToLoad = content;
      //this.headerObj.HSNCode = "998364";
      if (this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "radio") {
        this.setRadioItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }

    }
  }

  @ViewChild('othersItemsTableContainer', { static: false }) set othersTablecontent(content: OthersItemTableComponent) {
    this.othersItemsTableContainer = content;
    if (content) {
      if (this.dataToLoad && this.dataToLoad.header && this.dataToLoad.header.releaseType == "others") {
        this.setOthersItemsData(this.dataToLoad);
        this.calculateNetAmount()
        this.dataToLoad = null;
      }

    }
  }

  constructor(private route: ActivatedRoute, private newInvoiceService: NewInvoiceService, private cdRef:ChangeDetectorRef,
    private _snackBar: MatSnackBar, private dbAccessor: DBAccessor, private router: Router, private printService: PrintService, 
    private titleService: Title) {
      
    //this.headerObj.GSTIN = userConfig.GSTIN;
    this.headerObj.PAN = "AHFP" + "D1433Q";
    this.titleService.setTitle("Invoice");
    //this.addresseeList = userConfig.ROClients;
    this.dbAccessor.getConfig().then((configFile) => {
      if(configFile){
        this.freqAddresseeList = configFile.freqClients; // Clients are addressees now
        this.freqAddresseeListFiltered = this.freqAddresseeList;
        //this.freqClientList = configFile.freqClients;
        this.freqPublicationsList = configFile.freqPublications;
      }
    });
  }

  ngOnInit() {
    this.subscriptionContainer.add(this.route.data.subscribe(async (data) => {
      this.createMode = data.createMode;
      this.editMode = data.editMode;
      this.fromRO = data.fromRO;
      this.fromInvoice = data.fromInvoice;
      this.isProforma = data.isProforma;
      let nextNum = "";
      let flowPromise = Promise.resolve();

      if(this.isProforma){
        this.titleText = "Proforma Invoice";
        this.routerParentPath = "/Proforma";
        this.IDObj.objType = "PRF";
      }
      else{
        this.titleText = "Tax Invoice";
        this.routerParentPath = "/Invoice";
        this.IDObj.objType = "INV";
      }

      // Get next potential doc number, in case we toggle draft mode
      if (this.fromRO || this.fromInvoice || this.createMode) {
        this.headerObj.hasNewLetterhead = true;
        if(!this.isProforma){
          nextNum = await this.dbAccessor.getNextDocNum('Invoice');
          this.nextInvoice_num = nextNum;
          this.IDObj.billNo = nextNum;
            //this.currOrNextInvoice_ID = "ES-" + nextNum + '/' + DateUtil.getFinancialYear(); // Invoice contains the dash;
            //this.headerObj.billNo = this.currOrNextInvoice_ID;
          nextNum = await this.dbAccessor.getNextDocNum('InvoiceDraft')
          this.nextInvoiceDraft_num = nextNum;
            //outIDObjType = "INVDRAFT";
            //this.currOrNextInvoiceDraft_ID = "ES-DRAFTINV-" + nextNum + '/' + DateUtil.getFinancialYear();
          
        }
        else{
          this.headerObj.isProforma = true;
          nextNum = await this.dbAccessor.getNextDocNum('Proforma');
          this.nextProforma_num = nextNum;
          this.IDObj.billNo = nextNum;
            //outIDObjType = "PRF";
            //this.currOrNextProforma_ID = "ES-PRF-" + nextNum + '/' + DateUtil.getFinancialYear(); // Invoice contains the dash;
            //this.headerObj.billNo = this.currOrNextProforma_ID;
          
          
          nextNum = await this.dbAccessor.getNextDocNum('ProformaDraft');
          this.nextProformaDraft_num = nextNum;
            //outIDObjType = "PRFDRAFT";
            //this.currOrNextProformaDraft_ID = "ES-DRAFTPRF-" + nextNum + '/' + DateUtil.getFinancialYear(); // Invoice contains the dash;
          
        }
      }
      
      // Now set source data or initialize new doc or edit existing doc
      if (this.fromRO || this.fromInvoice) {
        // 
        let sourceData = this.newInvoiceService.getSourceData();
        if (sourceData) {
          if(this.fromRO){
            this.setInvoiceHeaderDataFromRO(sourceData);
            //this.totalObj = ROData.totalObj;
          }
          else{ // From invoice
            this.setInvoiceHeaderDataFromDB(sourceData);
            this.headerObj.isProforma = this.isProforma;
            this.setInvoiceTotalDataFromDB(sourceData);
          }
          this.dataToLoad = {
            header: sourceData.header,
            columnHeaderDetails: sourceData.columnHeaderDetails,
            items: sourceData.items
          }; // Will be picked up by item table setter
          this.dataToLoad.header.isDraft = false;
          
          // Cleanup
          this.newInvoiceService.clearData();
        }
      }
      else if(this.createMode){
        this.headerObj.releaseType = "print"; // Defaulting
        //this.headerObj.isProforma = this.isProforma;
        this.headerObj.hasNewLetterhead = true;
        this.headerObj.hasRemarks = true;
        if(this.isProforma)
          this.headerObj.remarks = userConfig.defaultProformaRemark;
        else
          this.headerObj.remarks = userConfig.defaultInvoiceRemark;
      }
      else { // editing existing doc
        this.subscriptionContainer.add(this.route.paramMap.subscribe((params) => {
          this.invoice_ID = params.get('id');
            
          flowPromise = flowPromise.then(() => {
              return this.dbAccessor.getByID(this.invoice_ID).then(async (InvoiceData) => { // Check later for race condition
              this.dataToLoad = InvoiceData;
              this.invoice_rev = InvoiceData._rev;
              this.invoice_rev = InvoiceData._rev;
              let billArr = InvoiceData.header.billNo.split('/');
              this.IDObj.billNo = billArr[billArr.length - 1];
              this.setInvoiceHeaderDataFromDB(InvoiceData);
              if(this.headerObj.isDraft){
                if(this.isProforma){
                  // Draft proforma ID is already present, must get non-draft ID
                  this.nextProformaDraft_num = this.getBillNumFromID(this.headerObj.billNo);
                  nextNum = await this.dbAccessor.getNextDocNum('Proforma');
                  this.nextProforma_num = nextNum;
                  //this.IDObj.billNo = nextNum;
                    //this.currOrNextProforma_ID = "ES-PRF-" + nextNum + '/' + DateUtil.getFinancialYear(); // Invoice contains the dash;
                  
                }
                else{
                  // Draft invoice ID is already present, must get non-draft ID
                  this.nextInvoiceDraft_num = this.getBillNumFromID(this.headerObj.billNo);
                  nextNum = await this.dbAccessor.getNextDocNum('Invoice');
                  this.nextInvoice_num = nextNum;
                  //this.IDObj.billNo = nextNum;
                    //this.currOrNextInvoice_ID = "ES-" + nextNum + '/' + DateUtil.getFinancialYear(); // Invoice contains the dash;
                  
                }
              }
              else{
                // Not draft
                if(this.isProforma){
                  // Non-draft proforma ID is already present, must get draft ID
                  this.nextProforma_num = this.getBillNumFromID(this.headerObj.billNo);
                  nextNum = await this.dbAccessor.getNextDocNum('ProformaDraft');
                  this.nextProformaDraft_num = nextNum;
                  //this.IDObj.billNo = nextNum;
                    //this.currOrNextProformaDraft_ID = "ES-DRAFTPRF-" + nextNum + '/' + DateUtil.getFinancialYear(); // Invoice contains the dash;
                  
                }
                else{
                  // Non-draft invoice ID is already present, must get draft ID
                  this.nextInvoice_num = this.getBillNumFromID(this.headerObj.billNo);
                  nextNum = await this.dbAccessor.getNextDocNum('InvoiceDraft');
                  this.nextInvoiceDraft_num = nextNum;
                  //this.IDObj.billNo = nextNum;
                    //this.currOrNextInvoiceDraft_ID = "ES-DRAFTINV-" + nextNum + '/' + DateUtil.getFinancialYear(); 
                  
                }
              }
              this.setInvoiceTotalDataFromDB(InvoiceData);
            }).catch((err) => {
              console.error("Document not found");
              console.log("Additional error details: " + err);
            })
          });
        }));
      }
      flowPromise.then(() => {
        this.refreshBillNo(this.headerObj.billDate);
      });
    }));
  }

  ngAfterViewInit() {

    
  }

  goToEditMode(){
    this.editMode = true;
  }

  getColumnHeaderDetails(){
    let columnHeaderDetailsObj = {};
    if(this.headerObj.releaseType == "print"){
      columnHeaderDetailsObj = this.printItemsTableContainer.getColumnHeaderDetails()
    }
    else if(this.headerObj.releaseType == "printClassified"){
      columnHeaderDetailsObj = this.printClassifiedItemsTableContainer.getColumnHeaderDetails()
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      columnHeaderDetailsObj = this.printFreeformItemsTableContainer.getColumnHeaderDetails()
    }
    else if(this.headerObj.releaseType == "radio"){
      columnHeaderDetailsObj = this.radioItemsTableContainer.getColumnHeaderDetails();
    }
    else if(this.headerObj.releaseType == "others"){
      columnHeaderDetailsObj = this.othersItemsTableContainer.getColumnHeaderDetails();
    }
    return columnHeaderDetailsObj;
  }

  getItemsArray() {
    let itemsArr;
    if (this.headerObj.releaseType == "print")
      itemsArr = this.printItemsTableContainer.getItemsArray();
    else if(this.headerObj.releaseType == "printClassified"){
      itemsArr = this.printClassifiedItemsTableContainer.getItemsArray()
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      itemsArr = this.printFreeformItemsTableContainer.getItemsArray()
    }
    else if (this.headerObj.releaseType == "radio")
      itemsArr = this.radioItemsTableContainer.getItemsArray();
    else if (this.headerObj.releaseType == "others")
      itemsArr = this.othersItemsTableContainer.getItemsArray();
    return itemsArr;
  }

  filterAddressees($event){
    if(!$event.srcElement.value || $event.srcElement.value.length == 0)
      this.freqAddresseeListFiltered = this.freqAddresseeList;
    else
      this.freqAddresseeListFiltered = this.freqAddresseeList.filter((el) => el.name.toLowerCase().includes($event.srcElement.value.toLowerCase()));
  }
  
  addresseeMenuOpened(){
    setTimeout(() => {
      this.addresseeSearchInput.nativeElement.focus();
    });
  }

  setInvoiceHeaderDataFromRO(inData){
    let inHeader = inData.header;
    this.headerObj.addressee.name = inHeader.client.name;
    this.headerObj.addressee.GSTIN = inHeader.client.GSTIN;
    this.headerObj.addressee.shortCode = inHeader.client.shortCode;
    //this.headerObj.billNo = inHeader.billNo;
    //this.headerObj.billDate = inHeader.billDate;
    this.headerObj.releaseType = inHeader.releaseType;
    this.headerObj.PAN = inHeader.PAN;
    this.headerObj.GSTIN = inHeader.GSTIN;
    this.headerObj.hasNewLetterhead = inHeader.hasNewLetterhead;
    this.headerObj.isDraft = false;
    this.headerObj.isProforma = this.isProforma;
    this.headerObj.hasRemarks = true;
    if(this.isProforma)
      this.headerObj.remarks = userConfig.defaultProformaRemark;
    else
      this.headerObj.remarks = userConfig.defaultInvoiceRemark;
    this.headerObj.splitGst = !!inHeader.splitGst;
    this.headerObj.showRefRO = !!inHeader.showRefRO;
    this.headerObj.refRO = inHeader.refRO;
    // Set Source Data
    /*
    this.headerObj.sourceRO = {
      RO_ID : inData.
      title : this.headerObj.client + " - " + DateUtil.getFormattedDate(this.headerObj.billDate)
    };*/
  }

  setInvoiceHeaderDataFromDB(inData){
    if(!inData)
      return;
    this.headerObj = inData.header;
  }

  setInvoiceTotalDataFromDB(inData){
    if(!inData)
      return;
    this.totalObj = inData.totalObj;
    this.totalObj.additionalComponents.forEach((addComp) => {
      if(addComp.id > this.lastPriceComponentID)
        this.lastPriceComponentID = addComp.id;
    });
  }

  setPrintItemsData(inData) {
    if (!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items: inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.printItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.printItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new PrintItem(inItem);
      this.printItemsTableContainer.addItem(invoiceItem);
      if(!this.IDObj.publication || this.IDObj.publication.length == 0){
        if(inItem.publicationShortCode){
          this.IDObj.publication = inItem.publicationShortCode;
          this.refreshBillNo(this.headerObj.billDate); // check if works without this
        }
      }
    });
    this.cdRef.detectChanges();
  }

  setPrintClassifiedItemsData(inData) {
    if (!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items: inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.printClassifiedItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.printClassifiedItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new PrintClassifiedItem(inItem);
      this.printClassifiedItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }

  setPrintFreeformItemsData(inData) {
    if (!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items: inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.printFreeformItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.printFreeformItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new PrintFreeformItem(inItem);
      this.printFreeformItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }

  setRadioItemsData(inData) {
    if (!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items: inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.radioItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.radioItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new RadioItem(inItem);
      this.radioItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }  

  setOthersItemsData(inData) {
    if (!inData)
      return;
    let invoiceData = {
      header : inData.header,
      columnHeaderDetails : inData.columnHeaderDetails,
      items: inData.items
    };
    if(invoiceData.columnHeaderDetails){
      this.othersItemsTableContainer.setColumnHeaderDetails(invoiceData.columnHeaderDetails);
    }
    this.othersItemsTableContainer.clearItems();
    invoiceData.items.forEach((inItem) => {
      let invoiceItem = new OthersItem(inItem);
      this.othersItemsTableContainer.addItem(invoiceItem);
    });
    this.cdRef.detectChanges();
  }  
  
  onAddresseeListItemClick(addrIndex){
    this.headerObj.addressee.name = this.freqAddresseeList[addrIndex].name;
    this.headerObj.addressee.GSTIN = this.freqAddresseeList[addrIndex].GSTIN;
    this.headerObj.addressee.shortCode = this.freqAddresseeList[addrIndex].shortCode;
    this.refreshBillNo(this.headerObj.billDate);
  }


  addItemClick(){
    if(this.headerObj.releaseType == "print"){
      this.printItemsTableContainer.addItem(new PrintItem(null));
    }
    else if(this.headerObj.releaseType == "printClassified"){
      this.printClassifiedItemsTableContainer.addItem(new PrintClassifiedItem(null));
    }
    else if(this.headerObj.releaseType == "printFreeform"){
      this.printFreeformItemsTableContainer.addItem(new PrintFreeformItem(null));
    }
    else if(this.headerObj.releaseType == "radio"){
      this.radioItemsTableContainer.addItem(new RadioItem(null));
    }
    else if(this.headerObj.releaseType == "others"){
      this.othersItemsTableContainer.addItem(new OthersItem(null));
    }
  }

  lastPriceComponentID = -1;

  getNewPriceComponentID(){
    return ++this.lastPriceComponentID;
  }

  addPriceComponentClick(){
    let newPriceComponent = {
      id : this.getNewPriceComponentID(),
      description : "",
      type : "discount",
      valueSign : "-",
      percentValue : 0, // only used for percentage price components
      value : 0
    };
    this.totalObj.additionalComponents.push(newPriceComponent);
  }

  deletePriceComponentClick(delPriceComponent){
    let foundIndex = this.totalObj.additionalComponents.findIndex((prcComponent) => prcComponent.id == delPriceComponent.id);
    if(foundIndex > -1){
      this.totalObj.additionalComponents.splice(foundIndex, 1);
      this.calculateNetAmount();
    }
  }

  calculateSubtotal() {
    let subtotalVal;
    //this.calculateGrossAmount();
    subtotalVal = this.totalObj.grossAmount;
    this.totalObj.additionalComponents.forEach((prcComponent) => {
      if(prcComponent.type == "percentDiscount" || prcComponent.type == "agencyDiscount") {
        if(prcComponent.percentValue == null || prcComponent.percentValue == ""){
          if(prcComponent.type == "percentDiscount")
            prcComponent.percentValue = 0;
          else
            prcComponent.percentValue = 15;
        }
        prcComponent.value = subtotalVal * (prcComponent.percentValue / 100);
      }
      if (prcComponent.valueSign == '+') {
        subtotalVal += parseInt(prcComponent.value) || 0;
      }
      else if (prcComponent.valueSign == '-') {
        subtotalVal -= parseInt(prcComponent.value) || 0;
      }
    });

    this.totalObj.subtotal.value = subtotalVal;
    return this.totalObj.subtotal;
  }

  calculateGST() {
    let gstPercent, gstAmount;
    if (this.headerObj.releaseType == "print" || this.headerObj.releaseType == "printClassified" || this.headerObj.releaseType == "printFreeform")
      gstPercent = 5;
    else
      gstPercent = 18;

    let subtotalObj = this.totalObj.subtotal;
    let subtotalValue = subtotalObj.value;
    gstAmount = (gstPercent / 100) * subtotalValue;

    this.totalObj.SGST = {
      description: "SGST @ " + gstPercent / 2 + "%",
      value: gstAmount / 2
    };
    this.totalObj.CGST = {
      description: "CGST @ " + gstPercent / 2 + "%",
      value: gstAmount / 2
    };
    this.totalObj.totalGST = {
      description: "GST @ " + gstPercent + "%",
      value: gstAmount
    };

    return this.totalObj.totalGST;

  }

  calculateGrossAmount() {
    let grossAmount = 0;
    let items = this.getItemsArray();
    items.forEach((item) => {
      if(!isNaN(item.amount))
        grossAmount += item.amount;
    });
    this.totalObj.grossAmount = grossAmount;
    //this.calculateNetAmount();
  }

  newPriceComponentChanged(priceComponent, event) {
    if (priceComponent.type == "agencyDiscount") {
      priceComponent.description = priceComponent.description || "Agency Discount";
      priceComponent.percentValue = 15;
      priceComponent.valueSign = '-';
    }
    else if(priceComponent.type == "percentDiscount"){
      priceComponent.valueSign = '-';
      priceComponent.description = priceComponent.description || "Percent Discount";
      priceComponent.percentValue = (priceComponent.percentValue > 0)? priceComponent.percentValue : 0;
      priceComponent.value = this.totalObj.grossAmount * (priceComponent.percentValue / 100);
    }
    else if (priceComponent.type == "discount") {
      priceComponent.description = priceComponent.description || "Discount";
      priceComponent.valueSign = '-';
    }
    else if (priceComponent.type == "surcharge") {
      priceComponent.description = priceComponent.description || "Premium";
      priceComponent.valueSign = '+';
    }
    this.calculateNetAmount();
  }


  calcAndSetAmountInWords() {
    let netAmount = Math.trunc(this.totalObj.netAmount);
    let netAmountWords = numWords(netAmount);
    let isFirstLetter = true;
    if (!netAmountWords)
      return;
    let netAmountWordsArr = [...netAmountWords];
    for (let i = 0; i < netAmountWordsArr.length; i++) {
      if (isFirstLetter && netAmountWordsArr[i] != 'a') {
        netAmountWordsArr[i] = netAmountWordsArr[i].toUpperCase(); // making it capital letters
      }
      if (isFirstLetter)
        isFirstLetter = false;
      else {
        if (netAmountWordsArr[i] == ' ')
          isFirstLetter = true;
      }
    }
    this.totalObj.amountInWords = netAmountWordsArr.join('') + ' Rupees only';
  }

  calculateNetAmount(itemsArr = null) {
    if(this.editMode){
      this.calculateGrossAmount();
      this.calculateSubtotal();
      let gstObj = this.calculateGST();
      this.totalObj.netAmount = this.totalObj.subtotal.value + gstObj.value;
      // Rounding off
      this.totalObj.netAmount -= (this.totalObj.netAmount % 1);
      this.calcAndSetAmountInWords();
    }
  }

  publicationTopModified(value){ // Disabled
    //this.IDObj.publication = value;
    //this.refreshBillNo();
  }

  getBillNumFromID(billID){
    let billNum = "";
    if(billID.startsWith('ES-')){ // old pattern
      let billArr = billID.split('/')[0].split('-');
      billNum = billArr[billArr.length - 1];
    }
    else if(billID.startsWith('ES/')){ // new pattern
      billNum = billID.split('/')[3];
    }
    return billNum;
  }

  refreshBillNo(billDate){
    this.IDObj.client = this.headerObj.addressee.shortCode;
    let outPrefix = "ES/" + DateUtil.getFinancialYear(billDate) + "/" + this.IDObj.objType + '/' + this.IDObj.billNo;
    let outSuffix = "";
    //let outID = "";
    /*
    if(this.IDObj.publication && this.IDObj.publication.length > 0){
      outSuffix += '/' + this.IDObj.publication;
    }
    if(this.IDObj.client && this.IDObj.client.length > 0){
      if(outSuffix.length > 0){
        outSuffix += '-';
      }
      else
        outSuffix = '/';
      outSuffix += this.IDObj.client;
    }*/
    if(this.headerObj.billNo == "" || this.headerObj.billNo.split('/')[0] == 'ES') // new format
      this.headerObj.billNo = outPrefix + outSuffix;
  }

  toggleDraftMode(params){
    this.headerObj.isDraft = !this.headerObj.isDraft;
    if(this.invoice_ID){ // existing doc
      this.hasDraftModeChanged = !this.hasDraftModeChanged;
    }
    if(!this.isProforma){
      this.IDObj.objType = "INV";
      if(params.checked){
        this.IDObj.objType += "DRAFT";
        this.IDObj.billNo = this.nextInvoiceDraft_num;
      }
      else{
        this.IDObj.billNo = this.nextInvoice_num;
      }
    }
    else{
      this.IDObj.objType = "PRF";
      if(params.checked){
        this.IDObj.objType += "DRAFT";
        this.IDObj.billNo = this.nextProformaDraft_num;
      }
      else{
        this.IDObj.billNo = this.nextProforma_num;
      }
    }
    
    this.refreshBillNo(this.headerObj.billDate);
  }

  validateInvoice() {
    let checkStatusObj = {
      checkStatus : true,
      errorMessages : []
    }
    if(!this.headerObj.addressee.name){
      checkStatusObj.checkStatus = false;
      checkStatusObj.errorMessages.push("Client name is mandatory");
    }
    if(!this.headerObj.addressee.GSTIN){
      checkStatusObj.checkStatus = false;
      checkStatusObj.errorMessages.push("Client GSTIN is mandatory");
    }
    if(!this.headerObj.billNo){
      checkStatusObj.checkStatus = false;
      checkStatusObj.errorMessages.push("Invoice No. invalid");
    }
    // Generic items check
    let itemsArray = this.getItemsArray();
    // Ensure there are items
    if(itemsArray.length == 0){
      checkStatusObj.checkStatus = false;
      checkStatusObj.errorMessages.push("No items in Invoice");
    }
      
    // Check whether some item has zero amount
    itemsArray.some((item) => {
      if(isNaN(item.amount)){
        checkStatusObj.checkStatus = false;
        checkStatusObj.errorMessages.push("At least one item in the Invoice has an invalid amount");
        return true; // break loop
      }
      else if(item.amount <= 0){
        checkStatusObj.checkStatus = false;
        checkStatusObj.errorMessages.push("At least one item in the Invoice has zero or less or invalid amount");
        return true; // break loop
      }
    });

    /* Specific checks
    if(this.headerObj.releaseType == "print"){
      
    }
    else if(this.headerObj.releaseType == "radio"){
      // Radio item validations
    }*/


    return checkStatusObj;
  }

  saveInvoice(){
    let checkStatusObj = this.validateInvoice();
    if(!checkStatusObj.checkStatus){
      // raise error
      checkStatusObj.errorMessages.forEach((errorMsg) => {
        this._snackBar.open(errorMsg, "", {
          duration: 3000,
        });
      });
      
      return;
    }
    if((this.headerObj.hasRemarks && this.headerObj.remarks.length == 0) || !this.headerObj.hasRemarks){
        this.headerObj.hasRemarks = false;
        this.headerObj.remarks = "";
    }

    let outObj = {
      header : this.headerObj,
      type : '',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    };
    
    if(this.headerObj.isProforma){
      outObj.type = "Proforma";
    }
    else{
      outObj.type = "Invoice";
    }


    this.isSaving = true;
    return this.dbAccessor.store(outObj).then((res:any) => {
      let docType = outObj.type;
      if(outObj.header.isDraft)
        docType += 'Draft';
      if(res.success) {
        this._snackBar.open("Successfully inserted " + docType + " : " + res.createdID, "", {
          duration: 3000,
        });
        this.dbAccessor.incrementNum(docType).then((res) => {
          let outPath = "/";
          if(outObj.type == "Invoice")
            outPath = "/Invoice";
          else if(outObj.type == "Proforma")
            outPath = "/Proforma";

          setTimeout(() => {
            this.router.navigate([outPath]);
          }, 2000);
        });
      }
      else {
        let errorMsg = res.error || "Unknown error";
        this._snackBar.open("An error occurred while inserting " + docType + " : " + errorMsg, "", {
          duration: 3000,
        });
      }
    })
  }

  updateInvoice(){
    let checkStatusObj = this.validateInvoice();
    if(!checkStatusObj.checkStatus){
      // raise error
      checkStatusObj.errorMessages.forEach((errorMsg) => {
        this._snackBar.open(errorMsg, "", {
          duration: 3000,
        });
      });
      
      return;
    }
    if((this.headerObj.hasRemarks && this.headerObj.remarks.length == 0) || !this.headerObj.hasRemarks){
      this.headerObj.hasRemarks = false;
      this.headerObj.remarks = "";
  }
    let outObj = {
      _id : this.invoice_ID,
      _rev : this.invoice_rev,
      header : this.headerObj,
      type : '',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    };

    if(this.headerObj.isProforma){
      outObj.type = "Proforma";
    }
    else{
      outObj.type = "Invoice";
    }

    this.isUpdating = true;

    return this.dbAccessor.update(outObj).then((res:any) => {
      let docType = outObj.type;
      if(res.success) {
        let outroPromise = Promise.resolve();
        if(this.hasDraftModeChanged){
          if(this.headerObj.isDraft){ // must increment draft number
            let incDocType = docType + "Draft";
            outroPromise = outroPromise.then(() => this.dbAccessor.incrementNum(incDocType));
          }
          else{ // must increment non-draft number
            outroPromise = outroPromise.then(() => this.dbAccessor.incrementNum(docType));
          }
        }
        outroPromise = outroPromise.then(() => {
          let outPath = "/";
          if(docType == "Invoice")
            outPath = "/Invoice";
          else if(docType == "Proforma")
            outPath = "/Proforma";
            
          this._snackBar.open("Successfully updated " + docType + " : " + res.updatedID, "", {
            duration: 3000,
          });
          setTimeout(() => {
            this.router.navigate([outPath]);
          }, 2000);
        });
        
      }
      else {
        let errorMsg = res.error || "Unknown error";
        this._snackBar.open("An error occurred while updating " + docType + " : " + errorMsg, "", {
          duration: 3000,
        });
      }
    })
  }

  printInvoice(){
    //this.router.navigate
    this.isPrinting = true;
    let docType;
    if(this.headerObj.isProforma){
      docType = "Proforma";
    }
    else{
      docType = "Invoice";
    }
    
    this.printService.printDocument(docType,this.invoice_ID);
  }

  createProformaCopy(){
    let outData = {
      invoice_ID : this.invoice_ID,
      invoice_rev : this.invoice_rev,
      header : this.headerObj,
      type : 'Proforma',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    }
    this.newInvoiceService.createNewInvoice(outData, true);
  }

  createInvoiceCopy(){
    let outData = {
      invoice_ID : this.invoice_ID,
      invoice_rev : this.invoice_rev,
      header : this.headerObj,
      type : 'Invoice',
      columnHeaderDetails : this.getColumnHeaderDetails(),
      items : this.getItemsArray(),
      totalObj : this.totalObj
    }
    this.newInvoiceService.createNewInvoice(outData, true);
  }

  refreshInvoice(){
    // REVISIT THIS LATER
    //this.router.navigate(['/RO',this.RO_ID]);
    this.isRefreshing = true;
    window.location.reload();
  }
}
