export class OthersItem {
  
    description : string
    amount : number
  
    constructor(inItem){
      if(inItem){
        this.description = inItem.description;
        this.amount = inItem.amount;
      }
      else{
        this.description = "";
        this.amount = 0;
      }
      
    }

  }